import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 55px;

  a {
    text-decoration: none;
    color: #0c78f3;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 65px;
  background: #fff;
  padding: 8px;

  button {
    width: calc(100% - 16px);
    height: 48px;
    background: var(--primary);
    color: #fff;
    border-radius: 12px;
    border: none;
    position: fixed;
    bottom: 10px;
    max-width: 410px;

    span {
      font-family: Poppins, Helvetica, sans-serif;
      font-weight: bold;
    }
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  h1 {
    margin-top: 10px;
    color: var(--primary);
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  p {
    margin-top: 10px;
    text-align: center;

    span {
      margin-top: 20px;
    }
  }
`;
