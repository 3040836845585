import styled from 'styled-components';
import 'react-credit-cards/es/styles-compiled.css';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  display: flex;
  margin-bottom: 50px;
  padding: 8px;
  flex-direction: column;
  height: 100vh;
  background: '#FFF';
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Card = styled.form`
  margin-top: 8px;
`;

export const Title = styled.div`
  padding: 16px;
  h1 {
    text-align: center;
  }
  p {
    text-align: center;
    margin-top: 8px;
  }
`;

export const ButtonGroup = styled.div`
  width: 100%;
  max-width: 410px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 65px;
  position: fixed;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #33333310;
`;

export const ChangeMethodButton = styled(Link)`
  width: 46%;
  height: 48px;
  background: #fff;
  color: var(--primary);
  border-radius: 7px;
  border: 1px solid var(--primary);
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled.button`
  width: 46%;
  height: 48px;
  background: var(--primary);
  color: #fff;
  border-radius: 7px;
  border: none;
`;

export const InputGroup = styled.div`
  display: flex;
  width: 100%;
  max-width: 410px;
  padding-bottom: 58px;
  div {
    font-size: 14px;
    & + div {
      margin-left: 8px;
    }
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;
`;
