import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderAction = styled.div`
  width: 100%;
  max-width: 410px;

  padding: 10px;

  position: fixed;
  top: 0;
  padding-top: 14px;
  padding-bottom: 54px;
  background: #fff;
  border-bottom: 1px solid #f8f8f8;
`;

export const Nav = styled.nav`
  width: 100%;
  max-width: 410px;

  position: fixed;
  top: 64px;

  display: flex;
  justify-content: center;

  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  li {
    display: inline-flex;
    list-style: none;
    color: var(--primary);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    & + li {
      border-left: 1px solid var(--primary) 70;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;

  display: flex;
  flex-direction: column;
  padding-bottom: 65px;
  margin-top: 65px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: #33333370;
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  background: #fff;
  border-radius: 7px;
  padding-bottom: 170px;
`;

export const RouterDomLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  color: ${shade(0.1, '#333333')};
`;

export const TabDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
