import React from 'react';

import Done from './Done';

import pedidoVazio from '../../../../assets/images/pedidoVazio.svg';

import { Container, Content, Orders, EmptyOrders } from './styles';

const OrdersInProgress = ({ ordersInProgress, schedulingList }) => {
  // const noBaskets =
  //   ordersInProgress.filter(order => order.order_status === 'ready') < 1 &&
  //   ordersInProgress.filter(order => order.order_status === 'accepted') < 1 &&
  //   ordersInProgress.filter(order => order.order_status === 'pending') < 1;
  return (
    <Container>
      <Content>
        <Orders>
          {ordersInProgress
            .filter(
              order =>
                order.order_status !== 'canceled' &&
                order.order_status !== 'finished' &&
                (schedulingList
                  ? order.scheduled_to !== null
                  : order.scheduled_to === null),
            )
            .map(order => (
              <Done order={order} key={order.id} />
            ))}
        </Orders>
        {ordersInProgress.length < 1 ? (
          <EmptyOrders>
            <h1>
              {/* Ops!
              <br /> */}
              <span>...estamos procurando seus pedidos.</span>
            </h1>
            <img
              src={pedidoVazio}
              alt="ilustracao de um homem com uma lupa procurando um pedido"
            />
          </EmptyOrders>
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default OrdersInProgress;
