import React from 'react';
import { ModalContainer, TextDiv } from './styles';

const ModalInfo = ({ isOpen, toggle, children }) => {
  return (
    <ModalContainer isOpen={isOpen} toggle={toggle}>
      {children}
    </ModalContainer>
  );
};

ModalInfo.Body = ({ children }) => {
  return <TextDiv>{children}</TextDiv>;
};

export default ModalInfo;
