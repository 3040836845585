import React, { useCallback, useEffect, useState } from 'react';
import { Progress } from 'reactstrap';

import {
  Order,
  OrderHeader,
  OrderBody,
  OrderStatus,
  OrderTime,
  OrderType,
  ButtonLink,
} from './styles';

import formatCompleteDate from '../../../../../utils/formatCompleteDate';
import { FiCopy } from 'react-icons/fi';

import { useAuth } from '../../../../../context/auth';
import { useBaskets } from '../../../../../context/BasketsContext';

const Done = ({ order }) => {
  const isWithdrawal = order.with_withdrawal;
  const { formatValue } = useAuth();
  const { fetchData, loadingUpdate } = useBaskets();
  const [buttonCopyText, setButtonCopyText] = useState('Copiar Código Pix');

  const getPixCode = useCallback(async () => {
    try {
      navigator.clipboard.writeText(order.pix_payments[0]?.code);
      setButtonCopyText('Código Copiado!');
    } catch (err) {
      console.log('Erro ao copiar Pix: ', err);
    }
  }, [order.pix_payments]);

  function searchStatusText(status) {
    switch (status) {
      case 'waiting_payment':
        return 'Aguardando Pagamento';

      case 'ready':
        return 'Saiu pra entrega';

      case 'accepted':
        return 'Preparando o pedido';

      case 'pending':
        return 'Aguardando o restaurante';
    }
  }

  function searchStatusColor(status) {
    switch (status) {
      case 'waiting_payment':
        return 'warning';

      case 'ready':
        return 'success';

      case 'accepted':
        return 'success';

      case 'pending':
        return 'warning';
    }
  }

  function searchTextColor(status) {
    switch (status) {
      case 'waiting_payment':
        return 'orange';

      case 'ready':
        return 'green';

      case 'accepted':
        return 'green';

      case 'pending':
        return 'orange';
    }
  }

  function searchStatusValue(status) {
    switch (status) {
      case 'waiting_payment':
        return '25';

      case 'ready':
        return '75';

      case 'accepted':
        return '50';

      case 'pending':
        return '25';
    }
  }

  return (
    <Order isWithdrawal={isWithdrawal} key={order.id}>
      <OrderType isWithdrawal={isWithdrawal}>
        {isWithdrawal ? 'Retirada' : 'Delivery'}
      </OrderType>
      <div style={{ padding: '10px 20px' }}>
        <OrderHeader>
          <p>{order.fantasy_name}</p>
          <p>{`Senha ${order.attendance_password}`}</p>
        </OrderHeader>
        <OrderTime>
          {`Pedido feito em ${formatCompleteDate(order.start_time)}`}
          <br />
          {order.scheduled_to &&
            `Agendado para: ${formatCompleteDate(order.scheduled_to)}`}
        </OrderTime>
        <OrderBody>
          {order.orders.map(item => (
            <p key={item.id}>{`${item.amount}x ${item.product.name}`}</p>
          ))}
        </OrderBody>
        <OrderTime>
          <p>Total: {formatValue(order?.total_delivery_price)}</p>
        </OrderTime>
        <OrderStatus status={order.order_status}>
          <Progress
            animated
            color={searchStatusColor(order.order_status)}
            value={searchStatusValue(order.order_status)}
            style={{ height: 10, animationDirection: 'reverse' }}
          />
          <p style={{ color: searchTextColor(order.order_status) }}>
            {order.with_withdrawal && order.order_status === 'ready'
              ? 'Pronto pra retirada'
              : searchStatusText(order.order_status)}
          </p>
          {order.order_status === 'waiting_payment' &&
            order.pix_payments.length > 0 && (
              <div>
                <FiCopy
                  size={20}
                  style={{ marginRight: 10, color: '#0CCAB4' }}
                />
                <ButtonLink onClick={() => getPixCode()}>
                  {buttonCopyText}
                </ButtonLink>
              </div>
            )}
        </OrderStatus>
        {order?.motoboy && (
          <p style={{ fontSize: '14px' }}>Entregador: {order.motoboy?.name}</p>
        )}

        <button
          onClick={fetchData}
          disabled={loadingUpdate}
          style={{
            border: 'none',
            background: 'transparent',
            textDecoration: 'underline',
            color: loadingUpdate ? 'lightgrey' : '#0CCAB4',
            fontWeight: 'bold',
            float: 'right',
          }}
        >
          Atualizar status
        </button>
      </div>
    </Order>
  );
};

export default Done;
