import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiArrowLeft, FiUser, FiUsers } from 'react-icons/fi';
import { useAuth } from '../../context/auth';

import IndividualAccount from '../IndividualAccount';
import TableAccount from '../TableAccount';

import {
  Container,
  Content,
  HeaderAction,
  RouterDomLink,
  Nav,
  TabDiv,
} from './styles';

const CloseAccount = () => {
  const { restaurantId, restaurantUseCommand, tableType } = useAuth();

  return (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <h1>{tableType !== 'prepaid' ? 'Fechar Conta' : 'Pedidos'}</h1>
            <RouterDomLink to={`/menu/${restaurantId}`}>
              <FiArrowLeft size={14} color="#c8131b" />{' '}
              <span style={{ color: '#c8131b' }}>Voltar</span>
            </RouterDomLink>
          </header>
        </HeaderAction>

        <Tabs>
          <Nav>
            <TabList>
              {tableType !== 'prepaid' && tableType !== 'lounge' && (
                <Tab>
                  <TabDiv>
                    <FiUsers color="#c8131b" />
                    {restaurantUseCommand.toString() === 'true' ? (
                      <span style={{ color: '#c8131b' }}>Comanda</span>
                    ) : (
                      <span style={{ color: '#c8131b' }}>Mesa</span>
                    )}
                  </TabDiv>
                </Tab>
              )}

              {tableType !== 'lounge' && (
                <Tab>
                  <TabDiv>
                    <FiUser color="#c8131b" />
                    <span style={{ color: '#c8131b' }}>Individual</span>
                  </TabDiv>
                </Tab>
              )}

              {tableType === 'lounge' && (
                <Tab>
                  <TabDiv>
                    <FiUser color="#c8131b" />
                    <span style={{ color: '#c8131b' }}>Lounge</span>
                  </TabDiv>
                </Tab>
              )}
            </TabList>
          </Nav>

          {tableType !== 'prepaid' && (
            <TabPanel>
              <TableAccount />
            </TabPanel>
          )}

          <TabPanel>
            <IndividualAccount />
          </TabPanel>
        </Tabs>
      </Content>
    </Container>
  );
};

export default CloseAccount;
