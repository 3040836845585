import React, { useContext, useEffect } from 'react';
import FormCard from '../../FormCard';
import { Button } from 'ui-kit-takeat';
import { CustomInput } from '../../CustomInput';
import { ClubFormContext } from '../../../../context/clubForm';
import { CustomSelect } from '../../CustomSelect';
import { GENDER_OPTIONS } from '../../../../utils/constants';

export const PersonalData = () => {
  const {
    getFormValue,
    updateForm,
    formErrors,
    setFormStep,
    validateForm,
  } = useContext(ClubFormContext);

  const searchParams = new URLSearchParams(window.location.search);
  const name = searchParams.get('name');

  useEffect(() => {
    if (name) {
      updateForm('name', name);
    }
  }, []);

  return (
    <>
      <FormCard.Header>Dados Pessoais</FormCard.Header>
      <FormCard.Body>
        <CustomInput
          placeholder="Digite seu nome"
          title="Nome completo*"
          name="name"
          value={getFormValue('name')}
          onChange={e => updateForm('name', e.target.value)}
          error={formErrors.name}
        />
        <CustomSelect
          label="Gênero*"
          name="gender"
          placeholder="Selecione"
          controlStyles={`border-radius: 10px; height: 48px;`}
          options={GENDER_OPTIONS}
          value={
            GENDER_OPTIONS.find(
              option => option.value === getFormValue('gender'),
            ) || null
          }
          onChange={e => updateForm('gender', e.value)}
          error={formErrors.gender}
        />
        <CustomInput
          placeholder="__/__/____"
          title="Data de nascimento*"
          type="tel"
          name="birthday"
          mask="99/99/9999"
          maskPlaceholder={null}
          value={getFormValue('birthday')}
          onChange={e => updateForm('birthday', e.target.value)}
          error={formErrors.birthday}
        />

        <FormCard.Info>
          Seus dados são coletados para que possamos proporcionar aos nossos
          clientes <b>benefícios promocionais</b> exclusivos.{' '}
          <span role="img" aria-label="Emojis de brilho e comemoração">
            ✨🎉
          </span>
        </FormCard.Info>
      </FormCard.Body>
      <FormCard.Buttons>
        <Button
          title="Voltar"
          inverted
          buttonColor="#c8131b"
          onClick={() => setFormStep(state => state - 1)}
        />
        <Button
          buttonColor="#2ec9b7"
          title="Próximo"
          containerStyles={{ flexGrow: 1 }}
          onClick={async () => {
            const validation = await validateForm(1);
            if (validation) {
              setFormStep(state => state + 1);
            }
          }}
        />
      </FormCard.Buttons>
    </>
  );
};
