/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';

import { FiArrowLeft } from 'react-icons/fi';

import { useAuth } from '../../../context/auth';

import {
  Container,
  Content,
  Header,
  PaymentsMethods,
  PaymentsMethodTitle,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  Payment,
  PaymentsMethodHeader,
  UserChangeInput,
  CancelButton,
  DeliveryTax,
} from './styles';

import logopicpay from '../../../assets/images/logopicpay.png';
import logocartao from '../../../assets/images/logocartao.png';
import logodinheiro from '../../../assets/images/dinheiro.svg';
import visa from '../../../assets/images/visa.png';
import pix from '../../../assets/images/pix.png';

import masterCard from '../../../assets/images/mastercard.png';
import american from '../../../assets/images/american.png';
import diners from '../../../assets/images/diners.png';
import elo from '../../../assets/images/elo.png';
import hipercard from '../../../assets/images/hipercard.png';
import { useCart } from '../../../context/cart';

const PaymentMethods = () => {
  const {
    setUserPaymentMethod,
    setUserChange,
    userDeliveryType,

    restaurantAcceptsPaytime,
    restaurantAcceptsPix,

    isDeliveryByDistance,
    restaurantDeliveryActive,
    restaurantAcceptsDelivery,
    userPhone,
    userName,
    restaurantDeliveryPayments,
  } = useAuth();

  const history = useHistory();
  const { addCartToOrder, closeCart } = useCart();

  // modal style
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');
  const [userChangeSet, setUserChangeSet] = useState();

  function closeModalWithChange() {
    setIsOpen(false);
    setUserChange(userChangeSet);
    history.push('/app/confirm-order-delivery');
  }

  function closeModal() {
    setIsOpen(false);
    history.push('/app/confirm-order-delivery');
    setUserChange(0);
  }

  function openModal() {
    setIsOpen(true);
  }

  function handleNavigate(payment_method, payment_token) {
    if (payment_method === 'pix') {
      setUserPaymentMethod({
        name: 'pix',
      });
      addCartToOrder(
        userPhone,
        userName,
        payment_method,
        'pdv',
        null,
        closeCart,
        payment_token,
      );
    } else if (payment_method === 'credito') {
      setUserPaymentMethod({
        name: 'credito',
      });
      history.push({
        pathname: '/app/inputcard',
        state: { userPhone, userName },
      });
    }

    // addCartToOrder(
    //   userPhone,
    //   userName,
    //   payment_method,
    //   null,
    //   'prepaid',
    //   commandTableNumber.toString(),
    //   closeCart,
    // );
    // history.push('/app/confirm-order-delivery');
  }

  function handleBack() {
    history.push('/app/cart');
  }

  return (
    <Container>
      <Content>
        <Header>
          <FiArrowLeft color="var(--primary)" onClick={handleBack} />
          <h1>Pagamento</h1>
        </Header>
        <PaymentsMethods>
          <PaymentsMethodTitle>Como você deseja pagar?</PaymentsMethodTitle>

          {restaurantAcceptsPix.toString() === 'true' && (
            <>
              <PaymentsMethodHeader>
                <p>Pagar online</p>
              </PaymentsMethodHeader>
              <Payment
                onClick={e => {
                  handleNavigate('pix');
                }}
                id="pix_online"
              >
                <div>
                  <img src={pix} alt="logopix" />
                </div>
                <p>Pix Online</p>
              </Payment>
            </>
          )}

          {restaurantAcceptsPaytime.toString() === 'true' && (
            <Payment
              onClick={e => {
                handleNavigate('credito');
              }}
              id="credito"
            >
              <div>
                <img
                  src={logocartao}
                  alt="logocartao"
                  style={{ width: '50px' }}
                />
              </div>
              <p>Crédito</p>
            </Payment>
          )}
        </PaymentsMethods>
      </Content>
    </Container>
  );
};

export default PaymentMethods;
