import React from 'react';

import {
  Order,
  OrderHeader,
  OrderBody,
  OrderFooter,
  OrderStatus,
  OrderTime,
} from './styles';
import formatCompleteDate from '../../../../../utils/formatCompleteDate';

const Canceled = ({ order }) => {
  return (
    <Order key={order.id}>
      <OrderHeader>
        <strong>{order.fantasy_name}</strong>
        <p>{`Senha ${order.attendance_password}`}</p>
      </OrderHeader>
      <OrderTime>
        {`Pedido feito em ${formatCompleteDate(order.start_time)}`}
      </OrderTime>
      <OrderBody>
        {order.orders.map(item => (
          <p key={item.id}>{`${item.amount}x ${item.product.name}`}</p>
        ))}
      </OrderBody>
      <OrderStatus status={order.order_status}>
        <p> Pedido cancelado </p>
      </OrderStatus>
      <OrderFooter
        to={{ pathname: '/app/delivery-orders-details', state: { order } }}
      >
        <p>Ver detalhes</p>
      </OrderFooter>
    </Order>
  );
};

export default Canceled;
