export const formatPrice = price => {
  if (price === '') {
    return '';
  }

  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price);
};
