import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QrReader from 'react-qr-reader';

import { useOrder } from '../../context/order';

import { Container, StyledLink } from './styles';

const Login = props => {
  const { closeOrder } = useOrder();
  const { restaurant_name } = useParams();
  const history = useHistory();
  const [qrcode, setQrcode] = useState();
  const [inputCode, setInputCode] = useState('');

  useEffect(() => {
    closeOrder();
  }, [closeOrder]);

  function handleScan(data) {
    if (data) {
      setQrcode(data);
    }
  }

  function handleSetInputCode(e) {
    setInputCode(e.target.value);
  }

  function handleError(err) {
    console.error(err);
    alert('Não foi encontrado dispositivo de câmera');
  }

  function handleNavigate() {
    if (!qrcode) {
      history.push(
        `/auto/${restaurant_name.toLocaleLowerCase()}/${inputCode.toLocaleLowerCase()}`,
      );
    } else {
      window.location.href = `${qrcode}`;
    }
  }

  return (
    <Container>
      <h1>
        Leia o QR Code que está na mesa, para preencher o codigo da mesa
        automaticamente, ou digite o codigo manualmente. <br />
      </h1>

      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: 260 }}
      />

      <input
        placeholder="Código da Mesa"
        value={qrcode}
        onChange={e => {
          handleSetInputCode(e);
        }}
      />

      <StyledLink onClick={handleNavigate}>Acessar cardápio</StyledLink>
    </Container>
  );
};

export default Login;
