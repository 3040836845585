import styled from 'styled-components';

export const SkeletonDiv = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ radius }) => radius};
  background: linear-gradient(45deg, #b1b1b1 0%, #dddddd 66%, #b1b1b1 100%);
  background-size: 400% 100%;
  animation: shimmer 4s ease-in-out infinite;

  @keyframes shimmer {
    0% {
      background-position: 50% 0;
    }
    50% {
      background-position: -50% 0;
    }
    100% {
      background-position: 50% 0;
    }
  }
`;
