import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Content, Footer } from './styles';
import { useAuth } from '../../context/auth';

import localizacao from '../../assets/images/localizacao.svg';

const Needlocation = () => {
  const { restaurantId } = useAuth();
  const history = useHistory();

  function handleNavigate() {
    history.push(`/menu/${restaurantId}`);
  }

  return (
    <Container>
      <Content>
        <img
          src={localizacao}
          alt="ilustracao de permissao de geolocalizacao"
        />
        <h1>Localização Necessária</h1>
        <p>
          Pedimos para
{' '}
<span>autorizar sua localização </span>
          assim que for solicitado, para confirmar que você esteja realmente por
          aqui e possa fazer seu pedido sem problemas.
        </p>
      </Content>
      <Footer>
        <button type="button" onClick={handleNavigate}>
          Ok, darei permissão
        </button>
      </Footer>
    </Container>
  );
};

export default Needlocation;
