import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container, Content, Footer } from './styles';

import { useOrder } from '../../context/order';

import errorImage from '../../assets/images/ERROR.png';

const SystemError = ({ error, errorMessage, errorAction }) => {
  const { setErrorComponent } = useOrder();
  const history = useHistory();

  function handleNavigate() {
    history.push('/app/auto');
  }

  return (
    <Container>
      <Content>
        <img src={errorImage} alt="ilustracao de erro ao fazer pedido" />

        <h1>Opa! Parece que nosso garçom digital está fora do ar</h1>
        <p>
          Mas não se preocupe, pois daqui a pouquinho tudo voltará ao normal.
          Enquanto isso, se precisar de algo, é só chamar o garçom do salão. Bom
          apetite!
        </p>
      </Content>
      <Footer>
        <button type="button" onClick={handleNavigate}>
          Ok, entendi
        </button>
      </Footer>
    </Container>
  );
};

export default SystemError;
