import React from 'react';

import { uuid } from 'uuidv4';

import LoadingOrders from '../LoadingOrders';

import pedidoVazio from '../../../../assets/images/pedidoVazio.svg';

import { Container, Content, Orders, EmptyOrders } from './styles';
import Canceled from './Canceled';
import Finished from './Finished';

const OrdersFinished = ({ ordersFinished }) => {
  const noBaskets =
    ordersFinished.filter(order => order.order_status === 'ready') < 1 &&
    ordersFinished.filter(order => order.order_status === 'accepted') < 1 &&
    ordersFinished.filter(order => order.order_status === 'pending') < 1;
  return (
    <Container>
      <Content>
        <Orders>
          {ordersFinished
            .filter(order => order.order_status === 'finished')
            .map(order => (
              <Finished order={order} key={uuid()} />
            ))}
          {ordersFinished
            .filter(order => order.order_status === 'canceled')
            .map(order => (
              <Canceled order={order} key={uuid()} />
            ))}
        </Orders>
        {noBaskets.length < 1 ? (
          <EmptyOrders>
            <h1>
              Ops!
              <br />
              <span>...não econtramos pedidos finalizados.</span>
            </h1>
            <img
              src={pedidoVazio}
              alt="ilustracao de um homem com uma lupa procurando um pedido"
            />
          </EmptyOrders>
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default OrdersFinished;
