import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ModalContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 1px 14px 0px #0000001f, 0px 5px 8px 0px #00000024,
    0px 3px 5px -1px #00000033;
  width: 288px;

  h1 {
    margin: 0;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #222222;
  }

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #545454;
  }
`;

export const CloseModal = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #ffb32f;
  border: none;

  svg {
    width: 32px;
    height: 32px;
  }
`;
