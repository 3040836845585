import React from 'react';
import { DefaultSelect, Spinner } from 'ui-kit-takeat';
import { SelectContainer } from './styles';
import { ErrorSpan } from '../CustomInput/styles';

export const CustomSelect = ({ ...rest }) => {
  const { error, isLoading } = rest;

  return (
    <SelectContainer>
      <DefaultSelect {...rest} />
      {error && <ErrorSpan>{error}</ErrorSpan>}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 35,
            right: 16,
          }}
        >
          <Spinner inverted background={'black'} />
        </div>
      )}
    </SelectContainer>
  );
};
