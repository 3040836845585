import styled from 'styled-components';

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  font-size: 14px;
  font-weight: 600;
`;

export const PriceSpan = styled.span`
  color: ${({ isPromotion }) => (isPromotion ? '#4d4d4c' : '#333333')};
  text-decoration: ${({ isPromotion }) =>
    isPromotion ? 'line-through' : 'none'};
`;
