import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #333;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 360px;
  max-width: 1000px;
  height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 55px;

  a {
    text-decoration: none;
    color: #0c78f3;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  width: 340px;
  height: 65px;
  position: fixed;
  bottom: 0;

  border-top: 1px solid #33333310;

  > div {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 300px;
  font-family: 'Poppins';

  img {
    width: 250px;
  }

  h1 {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
  }

  p {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;

    span {
      margin-top: 20px;
    }
  }
`;

export const ModalTitle = styled.div`
  margin-top: 16px;
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;
