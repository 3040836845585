import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;
  display: flex;
  padding-bottom: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  img {
    width: 200px;
  }

  h1 {
    margin-top: 16px;
    font-size: 20px;
  }

  p {
    text-align: center;
    margin-top: 8px;
    padding-right: 10px;
    padding-left: 10px;

    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  max-width: 410px;
  position: fixed;
  bottom: 0;

  button {
    width: 100%;
    max-width: 414px;
    height: 48px;
    background: linear-gradient(
      144deg,
      rgba(251, 74, 32, 1) 28%,
      rgba(250, 5, 97, 1) 100%
    );
    color: #fff;
    border-radius: 7px 7px 0 0;
    border: none;
  }
`;
