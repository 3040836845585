import styled from 'styled-components';

export const CategoryThumbnailLink = styled.a`
  width: 65px;
  height: 65px;
  position: relative;
  border-radius: 100%;
  border: 2px solid ${({ showing }) => (showing ? '#c8131b' : '#d9d9d9')};
  transition: border 0.3s;
`;

export const CategoryList = styled.li`
  display: inline-block;
  width: 80px;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  list-style: none;

  padding-top: 5px;

  p {
    font-size: 12px;
    max-width: 73px;
    margin-top: 8px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
