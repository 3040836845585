import React, { useContext } from 'react';
import FormCard from '../../FormCard';
import { CustomInput } from '../../CustomInput';
import { ClubFormContext } from '../../../../context/clubForm';
import { Button } from 'ui-kit-takeat';

export const Account = () => {
  const {
    getFormValue,
    updateForm,
    formErrors,
    setFormStep,
    validateForm,
  } = useContext(ClubFormContext);
  return (
    <>
      <FormCard.Header>Conta</FormCard.Header>
      <FormCard.Body>
        <CustomInput
          inputMode="email"
          name="email"
          placeholder="Digite um email"
          title="Email*"
          value={getFormValue('email')}
          onChange={e => updateForm('email', e.target.value)}
          error={formErrors.email}
        />
        <CustomInput
          name="password"
          placeholder="Digite uma senha"
          title="Senha*"
          type="password"
          value={getFormValue('password')}
          onChange={e => updateForm('password', e.target.value)}
          error={formErrors.password}
        />
        <CustomInput
          name="password-confirm"
          placeholder="Confirme a senha"
          title="Confirmar Senha*"
          type="password"
          value={getFormValue('confirmPassword')}
          onChange={e => updateForm('confirmPassword', e.target.value)}
          error={formErrors.confirmPassword}
        />
        <FormCard.Info>
          Coletamos essas informações para garantir que sua conta possa ser
          recuperada caso você esqueça a senha.{' '}
          <span role="img" aria-label="Emoji de cadeado">
            🔐
          </span>
        </FormCard.Info>
      </FormCard.Body>
      <FormCard.Buttons>
        <Button
          title="Voltar"
          inverted
          buttonColor="#c8131b"
          onClick={() => setFormStep(state => state - 1)}
        />
        <Button
          title="Próximo"
          onClick={async () => {
            const validation = await validateForm();
            if (validation) {
              setFormStep(state => state + 1);
            }
          }}
          buttonColor="#2ec9b7"
          containerStyles={{ flexGrow: 1 }}
        />
      </FormCard.Buttons>
    </>
  );
};
