import React, { useContext } from 'react';
import { HeaderContainer } from './styles';
import { BackButton } from '../../BackButton';
import { StoreInfo } from '../StoreInfo';
import { ProgressBar } from '../ProgressBar';
import { ClubFormContext } from '../../../context/clubForm';

export const Header = () => {
  const progressValue = [40, 65, 85, 100];
  const { formStep } = useContext(ClubFormContext);

  return (
    <HeaderContainer>
      <BackButton>Voltar</BackButton>
      <StoreInfo />
      <ProgressBar value={progressValue[formStep]} />
    </HeaderContainer>
  );
};
