import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container, Content, Footer } from './styles';

import { useOrder } from '../../context/order';

import errorImage from '../../assets/images/ERROR.png';
import { useAuth } from '../../context/auth';

const SystemDeliveryError = ({ error, errorMessage, errorAction }) => {
  const { setErrorComponent } = useOrder();
  const history = useHistory();
  const { restaurantName } = useAuth();

  function handleNavigate() {
    window.location.href = `https://pedido.takeat.app/${restaurantName}`;
  }

  return (
    <Container>
      <Content>
        <img src={errorImage} alt="ilustracao de erro ao fazer pedido" />

        <h1>Opa! Parece que nosso delivery está fora do ar</h1>
        <p>
          Mas não se preocupe, pois daqui a pouquinho tudo voltará ao normal.
          Enquanto isso, se precisar de algo, entre em contato com o
          restaurante.
        </p>
      </Content>
      <Footer>
        <button type="button" onClick={handleNavigate}>
          Tentar novamente
        </button>
      </Footer>
    </Container>
  );
};

export default SystemDeliveryError;
