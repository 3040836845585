import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;

  display: flex;
  flex-direction: column;
  padding-bottom: 65px;
  margin-top: 35px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    a {
      color: var(--primary);
      font-size: 12px;
      font-weight: 900;
    }
  }
`;

export const MenuContent = styled.div`
  border-radius: 7px;
  padding-bottom: 130px;

  padding-right: 10px;
  padding-left: 10px;
`;

export const MenuItem = styled.li`
  width: 100%;
  max-width: 410px;

  padding-top: 15px;

  list-style: none;

  div {
    font-family: Arial, Helvetica;
  }
`;

export const ButtonLink = styled.button`
  border: none;
  text-decoration: underline;
  color: ${props => (props.group ? '#fe1933' : '#0CCAB4')};
  background-color: transparent;
  font-weight: bold;
  margin-left: 0px;
  margin-bottom: 10px;
`;

export const AreaFinalPrice = styled.div``;

export const AreaButtons = styled.div`
  max-width: 410px;
  display: flex;
  flex-direction: column;
`;

export const CloseButton = styled.button`
  width: 100%;
  height: 40px;
  background: #c8131b;
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  border: none;
  margin-bottom: 10px;
  font-size: 14px;
`;

export const CloseButtonPix = styled.button`
  width: 100%;
  margin-top: 10px;
  height: 40px;
  background: #2ec9b7;
  color: #fff;
  font-weight: bold;
  border-radius: 7px;
  border: none;
  margin-bottom: 10px;
`;

export const Footer = styled.footer`
  width: 95%;
  max-width: 410px;

  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #fff;
  border-top: 1px solid #f8f8f8;
  height: auto;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);

  input {
    text-align: center;
    height: 48px;
    color: #fe1933;
    border-radius: 5px;
    border: 1px solid #fe1933;

    ::placeholder {
      color: #fe1933;
    }
  }
`;

export const ButtonActionToCart = styled.button`
  background: transparent;
  border: none;
`;

export const CartDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 38px;

  strong {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
  }
`;

export const ItemListHeader = styled.div`
  color: #333;
  font-size: 14px;
  padding: 10px;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const MenuItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-radius: 7px;
  padding: 5px;
  margin-top: 8px;

  p {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;

    svg {
      margin-right: 4px;
    }
  }

  strong {
    font-weight: 700;
  }
`;

export const Orders = styled.div`
  border-top: 1px solid #33333320;

  ul {
    width: 100%;
    max-width: 410px;
    li {
      list-style: none;
      display: flex;
      justify-content: space-between;

      > div {
        display: flex;

        > div {
          display: flex;
          margin-left: 8px;
        }

        p {
          margin-right: 8px;
          margin-top: 8px;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 14px;

  .bill_finished {
    color: red;
    text-decoration: line-through;
  }

  ul {
    li {
      p {
        display: flex;
        align-items: flex-end;
      }
      > div {
        > p {
          margin-right: 16px;
        }
      }
    }
  }
`;

export const MenuItemProducts = styled.div``;

export const ServiceTax = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    p {
      font-size: 14px;
    }
  }
`;

export const DescriptionText = styled.p`
  font-size: 14px;
  line-height: 20px;

  strong {
    color: #ff2b3a;
    font-size: 24px;
    font-weight: bold;
  }
`;

export const PriceText = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: #c8131b;
  font-weight: bold;
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
    font-size: 15px;
  }

  input {
    width: 100%;
    height: 48px;
    color: black;
    background: transparent;
    border-radius: 7px;
    border: 1px solid gray;
    padding: 3px;
    margin-bottom: 10px;
    text-align: center;

    &::placeholder {
      text-align: center;
    }
  }
`;

export const ConfirmButton = styled.button`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
  margin-bottom: 14px;

  :disabled {
    cursor: not-allowed;
    display: none;
  }
`;

export const ConfirmButtonOutline = styled.button`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-size: 14px;
  color: #2ec9b7;
  background: transparent;
  border: 1px solid #2ec9b7;
  border-radius: 7px;
  margin-bottom: 14px;

  :disabled {
    cursor: not-allowed;
    display: none;
  }
`;

export const OtherPayments = styled.p`
  ${props =>
    props.loading &&
    css`
      display: none;
    `}
`;

export const ConfirmPixButton = styled.button`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
  margin-bottom: 10px;
`;

export const ConfirmCreditButton = styled.button`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #01afff;
  border: none;
  border-radius: 7px;
  margin-bottom: 10px;
`;

export const CancelButton = styled.button`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-size: 14px;
  color: #ff2129;
  background: transparent;
  border: 1px solid #ff2129;
  border-radius: 7px;
  margin-bottom: 14px;
`;
