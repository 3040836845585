import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';
import ReactPixel from 'react-facebook-pixel';
import { uuid } from 'uuidv4';
import { useAuth } from '../../context/auth';
import Waiter from '../../assets/icons/confirm_waiter.svg'
import { Container, Content, Message, Footer } from './styles';
import { useCart } from '../../context/cart';
import { useOrder } from '../../context/order';
import { courtesyRestaurants } from '../../utils/constants';
import { Button } from 'ui-kit-takeat';

const ConfirmOrder = () => {
  const { restaurantId, basketId, restaurantPixelId } = useAuth();
  const { closeCartPixel, cartPixel } = useCart();
  const { ordersTotalValue } = useOrder();
  const history = useHistory();

  const isCourtesy = courtesyRestaurants.includes(restaurantId);

  function handleNavigate() {
    history.push(`/menu/${restaurantId}`);
  }

  // pixel_fb
  const aleatorID = uuid();
  const handleSendDataToPixel = useCallback(
    (items, ids) => {
      ReactPixel.fbq(
        'track',
        'Purchase',
        {
          content_ids: ids,
          currency: 'BRL',
          contents: items,
          value: ordersTotalValue,
        },
        {
          eventID: aleatorID,
        },
      );

      closeCartPixel();
    },
    [closeCartPixel, ordersTotalValue],
  );

  // const handleSendDataToApiConversion = useCallback(
  //   (items, ids) => {
  //     api.post('/public/pixel-api', {
  //       pixel_id: restaurantPixelId,
  //       access_token: restaurantMetaToken,
  //       data: [
  //         {
  //           event_name: 'Purchase',
  //           event_time: Math.floor(Date.now() / 1000),
  //           action_source: 'website',
  //           event_id: aleatorID,
  //           user_data: {
  //             client_user_agent: navigator.userAgent,
  //           },
  //           custom_data: {
  //             content_ids: ids,
  //             currency: 'BRL',
  //             contents: items,
  //             value: ordersTotalValue,
  //           },
  //         },
  //       ],
  //       partner_agent: 'Takeat',
  //     });

  //     closeCartPixel();
  //   },
  //   [
  //     aleatorID,
  //     closeCartPixel,
  //     ordersTotalValue,
  //     restaurantMetaToken,
  //     restaurantPixelId,
  //   ],
  // );

  useEffect(() => {
    const items = cartPixel.map(item => {
      return JSON.stringify({
        id: `${item.name}`,
        quantity: `${item.amount}`,
      });
    });
    const ids = cartPixel.map(item => item.name);
    if (restaurantPixelId) {
      handleSendDataToPixel(items, ids);
    }
  }, [restaurantPixelId, handleSendDataToPixel]);

  return (
    <Container>
      <Content>
        <Message>
          <img
            src={Waiter}
            style={{ width: 168, marginTop: 40 }}
            alt='Icone de um garçom segurando uma bandeja'
          />
          <h1>Pedido realizado com sucesso</h1>
          {/* <p style={{ color: "var(--primary)" }}>
            {isCourtesy
              ? `O Pedido ${basketId} já esta sendo preparado, e estará pronto em breve.`
              : `Pedido realizado com sucesso`}
          </p>{' '} */}
          <p style={{ color: "#979797" }}>Viu como é fácil fazer um pedido?</p>
        </Message>
        <Footer>
          <Button title='Voltar para o cardápio' onClick={handleNavigate} />
        </Footer>
      </Content>
    </Container>
  );
};

export default ConfirmOrder;
