import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background: #fff;
    color: #333;
    -webkit-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
  }

  #root{
    height: 100dvh;

    --primary: #C8131B;
  }

  body, input, button {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button{
    cursor: pointer;
  }

  li {
    list-style: none;

  }

  a {
    text-decoration: none;
  }

  .btn-primary {
    width: 100%;
  max-width: 414px;
    height: 48px;
    background: var(--primary);
    color: #fff;
    border-radius: 7px;
    border: none;
  }

  .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background: var(--primary);

  }

  .btn-secondary {
    width: 100%;
    max-width: 414px;
    height: 48px;
    color: var(--primary);
    background: transparent;
    border-radius: 7px;
    border: 1px solid var(--primary);
    margin-top: 10px;
  }


/* .accordion {
  width: 95%;
} */

/* .accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #ddd;
}

.accordion__button:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

`;
