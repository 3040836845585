import React, { useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import api from '../../../services/api';
import { Map, Marker, Draggable, ZoomControl } from 'pigeon-maps';
import { useAuth } from '../../../context/auth';
import {
  Modal as ModalPersonal,
  ModalHeader,
  ModalBody as ModalBodyStrap,
  ModalFooter as ModalFooterStrap,
} from 'reactstrap';
import * as Yup from 'yup';
import {
  Container,
  Content,
  Header,
  ButtonOrders,
  SelectField,
  SelectStyled,
  SelectGroup,
  Text,
  AddressField,
  StreetInputField,
  AcceptsPhrase,
  InputField,
  UserAddress,
  ButtonGroup,
  ChangeAddress,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  CancelButton,
  DesableCepButton,
  // ValidAddressTitle,
} from './styles';
import { Form } from '@unform/web';
import { FiAlertCircle } from 'react-icons/fi';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErrors';

const SearchAddress = () => {
  const {
    restaurantId,
    restaurantUsername,
    setUserAddressId,
    setUserAddress,
    userAddress,
    setRestaurantDeliveryTax,
    userLogout,
    restaurantAddress,
    changeUserInfo,
    isScheduling,
    setRestaurantDeliveryTimeAddress,
  } = useAuth();
  const history = useHistory();

  // modal style
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [modalErrorApi, setModalErrorApi] = useState(false);
  const [modalErrorApiToken, setModalErrorApiToken] = useState(false);
  const [modalPhrase, setModalPhrase] = useState('');
  Modal.setAppElement('#root');
  const [validCep, setValidCep] = useState(true);
  const [, setCount] = useState();
  const formRef = useRef(null);
  const [disableCep, setDisableCep] = useState(false);
  const [selectedState, setSelectedState] = useState(
    restaurantAddress?.state || '',
  );
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedNeihgboorhood, setSelectedNeihgboorhood] = useState('');
  const [selectedStreet, setSelectedStreet] = useState('');
  const [selectedNumber, setSelectedNumber] = useState('');
  const [selectedZipCode, setSelectedZipCode] = useState('');
  const [selectedComplement, setSelectedComplement] = useState('');
  const [selectedReference, setSelectedReference] = useState('');

  const [optionStates, setOptionStates] = useState([]);
  const [optionCities, setOptionCities] = useState([]);
  const [optionNeihgboorhood, setOptionNeihgboorhood] = useState([]);

  const [isZipCodeLimited, setIsZipCodeLimited] = useState('');
  const [acceptsAllNeihgboorhoods, setAcceptsAllNeihgboorhoods] = useState(
    false,
  );
  function toggleEditAddressInput() {
    setEditAddressInput(!editAddressInput);
  }
  const maskOnlyNumbers = value => {
    return value.replace(/\D/g, '');
  };

  function handleDisableCep() {
    setDisableCep(!disableCep);
  }

  const getCitiesData = useCallback(async () => {
    try {
      const res = await api.get(
        `/public/restaurants/delivery-addresses/${restaurantId}`,
      );

      const dataCountries = res.data.countries;

      dataCountries
        .map(country => country.states)
        .map(state =>
          state.map(item => {
            if (state.length === 1) {
              setSelectedState(item.name);
              setOptionStates('');
            } else if (state.length > 1) {
              setOptionStates(prevState => [
                ...prevState,
                {
                  value: item.name,
                  label: item.name,
                },
              ]);
            }
          }),
        );
    } catch (e) {
      console.log(e.message);
    }
  }, [restaurantId]);

  useEffect(() => {
    getCitiesData();
  }, [getCitiesData]);

  useEffect(() => {
    async function fetchDatas() {
      const res = await api.get(
        `/public/restaurants/delivery-addresses/${restaurantId}`,
      );

      const dataCountries = res.data.countries;

      const state = dataCountries
        .map(item => item.states)
        .map(item =>
          item.filter(statesFound => statesFound.name === selectedState),
        );

      const parsedCities = state.map(item =>
        item
          .map(citiesFound => citiesFound.cities)
          .map(cityArray =>
            cityArray.map(cityArrayFound => {
              return {
                value: cityArrayFound.name,
                label: cityArrayFound.name,
              };
            }),
          ),
      );

      setOptionCities(parsedCities[0][0]);
    }

    fetchDatas();
  }, [selectedState, restaurantId]);

  useEffect(() => {
    async function fetchData() {
      const res = await api.get(
        `/public/restaurants/delivery-addresses/${restaurantId}`,
      );

      const dataCountries = res.data.countries;

      const state = dataCountries
        .map(item => item.states)
        .map(item =>
          item.filter(statesFound => statesFound.name === selectedState),
        );

      const cityParsed = state.map(item =>
        item
          .map(it => it.cities)
          .map(cty => cty.filter(ct => ct.name === selectedCity)),
      );

      const neighborhoodsFound = [];

      cityParsed[0].map(ct =>
        ct.map(nb => {
          nb.neighborhoods.map(nbfound => neighborhoodsFound.push(nbfound));
          if (nb.neighborhoods.length > 0) {
            setAcceptsAllNeihgboorhoods(false);
          } else {
            setAcceptsAllNeihgboorhoods(true);
          }
        }),
      );

      // setDeliveryNeihgboorhood(neighborhoodsFound);

      const neighborhoodsParsed = neighborhoodsFound.map(nb => {
        return {
          value: nb.name,
          label: nb.name,
        };
      });

      if (neighborhoodsParsed.length > 0) {
        setOptionNeihgboorhood(neighborhoodsParsed);
      } else {
        setOptionNeihgboorhood('');
      }
    }
    fetchData();
  }, [selectedCity, selectedState, restaurantId]);

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await api.get(`/public/restaurant/${restaurantUsername}`);

  //     const { delivery_addresses } = res.data;

  //     const neighboorhoodFind = delivery_addresses
  //       .map(add => add)
  //       .filter(ad => ad.neighborhood === selectedNeihgboorhood);

  //     if (neighboorhoodFind.length > 0) {
  //       setIsZipCodeLimited(neighboorhoodFind[0].is_zip_code_limited);
  //     }
  //   }

  //   fetchData();
  // }, [selectedNeihgboorhood, restaurantUsername, setRestaurantDeliveryTax]);

  function handleNavigate() {
    history.push('/app/delivery-type');
  }

  function handleChangeAddress() {
    setUserAddress('');

    const aleatoryId = uuid();
    setCount(aleatoryId);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeErrorModal() {
    setModalErrorIsOpen(false);
  }

  function closeModalErrorApi() {
    setModalErrorApi(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openErrorModal() {
    setModalErrorIsOpen(true);
  }

  function openModalErrorApi() {
    setModalErrorApi(true);
  }

  function toggleModalErrorApiToken() {
    setModalErrorApiToken(!modalErrorApiToken);
  }

  function closeModalWithChange() {
    setIsOpen(false);
    handleChangeAddress();
  }

  function handleNavigateLogin() {
    toggleModalErrorApiToken();
    history.push('/app/cart-delivery');
  }

  async function handleValidateAddress() {
    try {
      const response = await api.post(
        '/client/delivery-addresses/validate-and-save',
        {
          country: 'BR',
          state: userAddress.state || '',
          city: userAddress.city,
          neighborhood: userAddress.neighborhood,
          street: userAddress.street,
          number: userAddress.number,
          complement: userAddress.complement || '',
          reference: userAddress.reference,
          zip_code: userAddress.zip_code || '',
          restaurant_id: restaurantId,
        },
      );

      if (response.data.valid_address === true) {
        setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
        setRestaurantDeliveryTimeAddress(response.data.address.delivery_time);

        setUserAddressId(response.data.address.id);

        if (isScheduling) {
          history.push('/app/schedule?type=delivery');
        } else {
          history.push('/app/payments-delivery');
        }
      } else {
        openModal();
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'token_not_sent':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'buyer_outside_bounds':
          case 'delivery_max_distance':
            openModal();
            break;

          case 'google_api_status_not_ok':
            openModal();
            break;

          case 'address_not_found':
            openModal();
            break;

          case 'zip_code_not_found':
            openModal();
            break;

          default:
            openModalErrorApi();
        }
      }
    }
  }
  const [
    isModalConfirmAddressOpened,
    setIsModalConfirmAddressOpened,
  ] = useState(false);
  const [confirmAddress, setConfirmAddress] = useState({});

  function toggleModalConfirmAddress() {
    // setIsModalConfirmAddressOpened(!isModalConfirmAddressOpened);
    // setEditAddressInput(false);
  }

  const [editAddressInput, setEditAddressInput] = useState(false);
  const [coordinates, setCoordinates] = useState([]);

  async function handleRegisterAddress() {
    try {
      if (
        !!selectedCity &&
        !!selectedState &&
        !!selectedNeihgboorhood &&
        !!selectedStreet &&
        !!selectedNumber
      ) {
        // const res = await api.post('/client/delivery-addresses', {
        //   country: 'BR',
        //   state: selectedState,
        //   city: selectedCity,
        //   neighborhood: selectedNeihgboorhood,
        //   street: selectedStreet,
        //   number: selectedNumber,
        //   complement: selectedComplement || '',
        //   reference: selectedReference,
        //   zip_code: selectedZipCode || '',
        //   restaurant_id: restaurantId,
        // });

        const response = await api.post(
          '/client/delivery-addresses/validate-and-save',
          {
            country: 'BR',
            state: selectedState,
            city: selectedCity,
            neighborhood: selectedNeihgboorhood,
            street: selectedStreet,
            number: selectedNumber,
            complement: selectedComplement || '',
            reference: selectedReference,
            zip_code: selectedZipCode || '',
            restaurant_id: restaurantId,
          },
        );

        // setConfirmAddress(response.data);

        // const { latitude, longitude } = response.data;

        // setCoordinates([latitude, longitude]);

        if (response.data.valid_address === true) {
          setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
          setRestaurantDeliveryTimeAddress(response.data.address.delivery_time);

          setUserAddressId(response.data.address.id);

          setUserAddress({
            country: 'BR',
            state: selectedState,
            city: selectedCity,
            neighborhood: selectedNeihgboorhood,
            street: selectedStreet,
            number: selectedNumber,
            complement: selectedComplement || '',
            reference: selectedReference || '',
            zip_code: selectedZipCode || '',
          });

          if (isScheduling) {
            history.push('/app/schedule?type=delivery');
          } else {
            history.push('/app/payments-delivery');
          }
        } else {
          openModal();
        }
      } else {
        openErrorModal();
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'token_not_sent':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'buyer_outside_bounds':
          case 'delivery_max_distance':
            openModal();
            break;

          case 'google_api_status_not_ok':
            openModal();
            break;

          case 'address_not_found':
            openModal();
            break;

          case 'zip_code_not_found':
            openModal();
            break;

          default:
            openModalErrorApi();
        }
      }
    }
  }

  const formConfirmAddressRef = useRef(null);

  async function handleSetCoordinates(data) {
    setCoordinates([data[0], data[1]]);

    const response = await api.get('/client/delivery-addresses/location', {
      params: {
        latitude: data[0],
        longitude: data[1],
      },
    });

    setConfirmAddress(response.data);

    const streetInput = formConfirmAddressRef.current.getFieldRef('street');
    const neighboorhoodInput = formConfirmAddressRef.current.getFieldRef(
      'neighborhood',
    );
    const cityInput = formConfirmAddressRef.current.getFieldRef('city');
    const numberInput = formConfirmAddressRef.current.getFieldRef('number');

    streetInput.value = response.data.street;
    neighboorhoodInput.value = response.data.neighborhood;
    cityInput.value = response.data.city;
    numberInput.value = response.data.number;
  }

  async function handleValidateAddressAfterSave() {
    try {
      const response = await api.post(
        'client/restaurant-delivery-address/validate',
        {
          restaurant_id: restaurantId,
          delivery_address: {
            city: userAddress.city || '',
            country: userAddress.country || '',
            state: userAddress.state || '',
            neighborhood: userAddress.neighborhood || '',
            zip_code: userAddress.zip_code || '',
            street: userAddress.street || '',
            number: userAddress.number || '',
          },
        },
      );

      if (response.data.valid_address === true) {
        setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
        setRestaurantDeliveryTimeAddress(response.data.address.delivery_time);

        if (isScheduling) {
          history.push('/app/schedule?type=delivery');
        } else {
          history.push('/app/payments-delivery');
        }
        setCoordinates([]);
      } else {
        toggleModalConfirmAddress();
        openModal();
        setUserAddressId('');
        setEditAddressInput(false);
        setCoordinates([]);
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'token_not_sent':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'buyer_outside_bounds':
          case 'delivery_max_distance':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'delivery_no_distance_found':
            setModalPhrase(
              'Há inconsistência no cadastro de delivery. Favor comunicar ao restaurante',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'google_api_status_not_ok':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'address_not_found':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'zip_code_not_found':
            setModalPhrase('Infelizmente não encontramos este CEP informado.');
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);
            break;

          default:
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);
        }
      }
    }
  }

  // async function handleValidateAddressAndSubmit(data) {
  //   try {
  //     if (formRef.current) {
  //       formRef.current.setErrors({});
  //     }
  //     const schema = Yup.object().shape({
  //       street: Yup.string().required('Nome obrigatório'),
  //       number: Yup.string()
  //         .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
  //         .required('Número obrigatório'),
  //       city: Yup.string().required('Cidade é obrigatória'),
  //       neighborhood: Yup.string().required('Bairro obrigatório'),
  //     });

  //     await schema.validate(data, {
  //       abortEarly: false,
  //     });

  //     const res = await api.post('/client/delivery-addresses', {
  //       country: 'BR',
  //       state: selectedState,
  //       city: data.city,
  //       neighborhood: data.neighborhood,
  //       street: data.street,
  //       number: data.number,
  //       complement: data.complement || '',
  //       reference: data.reference,
  //       zip_code: data.zip_code || '',
  //       restaurant_id: restaurantId,
  //     });

  //     // setConfirmAddress(data);
  //     // toggleModalConfirmAddress();

  //     // const { latitude, longitude } = res.data;

  //     // setCoordinates([latitude, longitude]);

  //     const response = await api.post(
  //       'client/restaurant-delivery-address/validate',
  //       {
  //         restaurant_id: restaurantId,
  //         delivery_address: {
  //           city: data.city || '',
  //           country: 'BR',
  //           state: selectedState || '',
  //           neighborhood: data.neighborhood || '',
  //           zip_code: data.zip_code || '',
  //           street: data.street || '',
  //           number: data.number || ''
  //         },
  //       },
  //     );

  //     if (response.data.valid_address === true) {
  //       setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
  //       setRestaurantDeliveryTimeAddress(response.data.address.delivery_time)
  //       setUserAddressId(res.data.id);

  //       setUserAddress({
  //         country: 'BR',
  //         state: selectedState,
  //         city: data.city,
  //         neighborhood: data.neighborhood,
  //         street: data.street,
  //         number: data.number,
  //         complement: data.complement || '',
  //         reference: data.reference || '',
  //         zip_code: data.zip_code || '',
  //       });

  //       if (isScheduling) {
  //         history.push('/app/schedule?type=delivery');
  //       } else {
  //         history.push('/app/payments-delivery');
  //       }
  //       setCoordinates([]);
  //     } else {
  //       openModal();
  //       setEditAddressInput(false);
  //       toggleModalConfirmAddress();
  //       setCoordinates([]);
  //     }
  //   } catch (err) {
  //     if (err instanceof Yup.ValidationError) {
  //       const errors = getValidationErrors(err);
  //       if (formRef.current) {
  //         formRef.current.setErrors(errors);
  //       }
  //     } else if (!err.response.ok) {
  //       switch (err.response.data.errorType) {
  //         case 'invalid_token':
  //           userLogout();
  //           changeUserInfo();
  //           toggleModalErrorApiToken();
  //           toggleModalConfirmAddress();
  //           setCoordinates([]);

  //           break;

  //         case 'token_setConfirmAddressnot_sent':
  //           userLogout();
  //           changeUserInfo();
  //           toggleModalErrorApiToken();
  //           toggleModalConfirmAddress();
  //           setCoordinates([]);

  //           break;

  //         case 'buyer_outside_bounds':
  //         case 'delivery_max_distance':
  //           setModalPhrase(
  //             'Infelizmente não entregamos neste endereço informado.',
  //           );
  //           openModalErrorApi();
  //           toggleModalConfirmAddress();
  //           setCoordinates([]);

  //           break;

  //         case 'google_api_status_not_ok':
  //           setModalPhrase(
  //             'Infelizmente não entregamos neste endereço informado.',
  //           );
  //           openModalErrorApi();
  //           toggleModalConfirmAddress();
  //           setCoordinates([]);

  //           break;

  //         case 'delivery_no_distance_found':
  //           setModalPhrase(
  //             'Há inconsistência no cadastro de delivery. Favor comunicar ao restaurante',
  //           );
  //           openModalErrorApi();
  //           toggleModalConfirmAddress();
  //           setCoordinates([]);

  //           break;

  //         case 'address_not_found':
  //           setModalPhrase(
  //             'Infelizmente não entregamos neste endereço informado.',
  //           );
  //           openModalErrorApi();
  //           toggleModalConfirmAddress();
  //           setCoordinates([]);

  //           break;

  //         case 'zip_code_not_found':
  //           setModalPhrase('Infelizmente não encontramos este CEP informado.');
  //           openModalErrorApi();
  //           toggleModalConfirmAddress();
  //           setCoordinates([]);

  //           break;

  //         default:
  //           setModalPhrase(
  //             'Houve um erro ao cadastrar seu endereço, verifique seus dados digitados.',
  //           );
  //           toggleModalConfirmAddress();
  //           openModalErrorApi();
  //           setCoordinates([]);
  //       }
  //     }
  //   }
  // }

  return (
    <Container>
      {/* <ModalPersonal
        isOpen={isModalConfirmAddressOpened}
        toggle={toggleModalConfirmAddress}
      >
        <Form
          ref={formConfirmAddressRef}
          onSubmit={handleValidateAddressAndSubmit}
          initialData={confirmAddress}
        >
          <ModalHeader>
            <p>Confirme seu endereço.</p>
          </ModalHeader>

          <ModalBodyStrap>
            {coordinates.length > 0 && (
              <Map height={300} defaultCenter={coordinates} defaultZoom={17}>
                {!editAddressInput && (
                  <Marker width={50} anchor={coordinates} />
                )}

                {editAddressInput && (
                  <Draggable
                    offset={[60, 87]}
                    onDragEnd={data => handleSetCoordinates(data)}
                    anchor={coordinates}
                  >
                    <Marker width={50} />
                  </Draggable>
                )}
              </Map>
            )}

            {editAddressInput && (
              <AddressField style={{ width: '100%' }}>


                <StreetInputField>
                  <InputField>
                    <Input
                      type="text"
                      placeholder="Digite sua rua ..."
                      name="street"
                      id="street"
                      autoComplete="off"
                    />
                  </InputField>

                  <InputField>
                    <Input
                      type="text"
                      placeholder="Número ..."
                      name="number"
                      autoComplete="off"
                    />
                  </InputField>
                </StreetInputField>

                <InputField>
                  <Input
                    type="text"
                    placeholder="Bairro ..."
                    name="neighborhood"
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <Input
                    type="text"
                    placeholder="Cidade ..."
                    name="city"
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <Input
                    type="text"
                    name="complement"
                    placeholder="Complemento ..."
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <Input
                    type="text"
                    name="reference"
                    placeholder="Referência ..."
                  />
                </InputField>
              </AddressField>
            )}
          </ModalBodyStrap>

          <ModalFooterStrap>
            {editAddressInput ? (
              <CancelButton
                type="button"
                onClick={() => {
                  toggleModalConfirmAddress();
                  setCoordinates([]);
                }}
              >
                Cancelar
              </CancelButton>
            ) : (
              <CancelButton type="button" onClick={toggleEditAddressInput}>
                Editar
              </CancelButton>
            )}

            {editAddressInput ? (
              <ConfirmButton type="submit">Confirmar</ConfirmButton>
            ) : (
              <ConfirmButton onClick={handleValidateAddressAfterSave}>
                Confirmar
              </ConfirmButton>
            )}
          </ModalFooterStrap>
        </Form>
      </ModalPersonal> */}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Infelizmente não entregamos no endereço informado.</p>
        </ModalTitle>

        <ModalFooter>
          <CancelButton onClick={closeModal}>Cancelar</CancelButton>
          <ConfirmButton onClick={closeModalWithChange}>
            Alterar meu endereço
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorApi}
        onRequestClose={closeModalErrorApi}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>
            Houve um erro ao cadastrar seu endereço, verifique seus dados
            digitados.
          </p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={closeModalErrorApi}>
            Ok, irei verificar
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorIsOpen}
        onRequestClose={closeErrorModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Favor preencher os dados corretamente.</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={closeErrorModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorApiToken}
        onRequestClose={toggleModalErrorApiToken}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>
            Seu login expirou! Por segurança pedimos que faça login novamente,
            preenchendo seus dados. Iremos te direcionar para o login.
          </p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={handleNavigateLogin}>
            Ok, entendi
          </ConfirmButton>
        </ModalFooter>
      </Modal>
      {userAddress ? (
        <Content>
          <Header>
            <FiArrowLeft color="var(--primary)" onClick={handleNavigate} />
            <h1>Endereço Cadastrado</h1>
          </Header>

          <Text>
            <p>
              Você cadastrou esse endereço. Verifique se o restaurante atual
              entrega neste informado, ou altere o endereço.
            </p>
          </Text>

          <UserAddress>
            <p>
              {`${userAddress.street && `Logradouro: ${userAddress.street}`}, ${
                userAddress.number
              }`}
            </p>
            <p>
              {(userAddress.neighborhood &&
                `Bairro: ${userAddress.neighborhood}`) ||
                ''}
            </p>
            <p>
              {(userAddress.zip_code && `CEP: ${userAddress.zip_code}`) || ''}
            </p>
            <p>
              {(userAddress.complement &&
                `Complemento: ${userAddress.complement}`) ||
                ''}
            </p>
            <p>
              {(userAddress.reference &&
                `Referência: ${userAddress.reference}`) ||
                ''}
            </p>
            <p>{`${userAddress.city} - ${userAddress.state}`}</p>
          </UserAddress>

          <ButtonGroup>
            <ChangeAddress onClick={handleChangeAddress}>
              Alterar endereço
            </ChangeAddress>
          </ButtonGroup>

          <ButtonOrders onClick={handleValidateAddress}>
            Verificar e Prosseguir
          </ButtonOrders>
        </Content>
      ) : (
        <Content>
          <Header>
            <FiArrowLeft color="var(--primary)" onClick={handleNavigate} />
            <h1>Endereço para entrega</h1>
          </Header>

          <Text>
            <p>
              Verifique abaixo se temos disponibilidade para delivery em seu
              bairro.
            </p>
          </Text>

          <SelectGroup>
            {optionStates.length > 0 && (
              <SelectField>
                <p>Estado (UF)</p>
                <SelectStyled
                  name="state"
                  options={optionStates}
                  placeholder="Selecione seu estado ..."
                  onChange={e => {
                    setSelectedState(e.value);
                  }}
                  isSearchable
                  noOptionsMessage={() => 'nada encontrado ...'}
                />
              </SelectField>
            )}

            <SelectField>
              <p>Cidade</p>
              <SelectStyled
                name="cities"
                options={optionCities}
                placeholder="Selecione sua cidade ..."
                onChange={e => {
                  setSelectedCity(e.value);
                }}
                isSearchable
                noOptionsMessage={() => 'nada encontrado ...'}
              />
            </SelectField>

            {optionNeihgboorhood.length > 0 && (
              <SelectField>
                <p>Bairro</p>
                <SelectStyled
                  name="neihgboorhoods"
                  options={optionNeihgboorhood}
                  placeholder="Selecione seu bairro ..."
                  onChange={e => {
                    setSelectedNeihgboorhood(e.value);
                  }}
                  isSearchable
                  noOptionsMessage={() => 'nada encontrado ...'}
                />
              </SelectField>
            )}
          </SelectGroup>

          {acceptsAllNeihgboorhoods.toString() === 'true' && (
            <AcceptsPhrase>
              <p>{`Aceitamos delivery em todos os bairros em ${selectedCity}`}</p>
            </AcceptsPhrase>
          )}

          {!!selectedState && !!selectedCity && (
            <AddressField>
              <p>Agora nos diga onde você mora</p>

              <StreetInputField>
                <InputField>
                  <p>Rua</p>
                  <input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                    value={selectedStreet}
                    onChange={e => {
                      setSelectedStreet(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <p>Número</p>
                  <input
                    type="text"
                    placeholder="Num."
                    name="number"
                    id="number"
                    value={selectedNumber}
                    onChange={e => {
                      setSelectedNumber(maskOnlyNumbers(e.target.value));
                    }}
                    autoComplete="off"
                  />
                </InputField>
              </StreetInputField>
              {optionNeihgboorhood.length === 0 && (
                <InputField>
                  <p>Bairro</p>
                  <input
                    type="text"
                    placeholder="Bairro"
                    name="neighboorhoodInput"
                    value={selectedNeihgboorhood}
                    onChange={e => {
                      setSelectedNeihgboorhood(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </InputField>
              )}

              {isZipCodeLimited === true && (
                <InputField>
                  <p>CEP</p>
                  <input
                    type="text"
                    placeholder="Seu CEP"
                    name="cep"
                    value={selectedZipCode}
                    onChange={e => {
                      setSelectedZipCode(maskOnlyNumbers(e.target.value));
                    }}
                    autoComplete="off"
                  />
                </InputField>
              )}

              <InputField>
                <p>Complemento</p>
                <input
                  type="text"
                  name="complement"
                  placeholder="Ex: Casa 3, fundos"
                  value={selectedComplement}
                  onChange={e => {
                    setSelectedComplement(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <p>Referência</p>
                <input
                  type="text"
                  name="reference"
                  placeholder="Ex: Prox. ao mercado x"
                  value={selectedReference}
                  onChange={e => {
                    setSelectedReference(e.target.value);
                  }}
                  autoComplete="off"
                />
              </InputField>
            </AddressField>
          )}

          <ButtonOrders onClick={handleRegisterAddress}>
            Cadastrar endereço
          </ButtonOrders>
        </Content>
      )}
    </Container>
  );
};

export default SearchAddress;
