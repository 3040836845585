import React, { useState, useEffect, useCallback, useRef } from 'react';

import { CategoryList, CategoryThumbnailLink } from './styles';
import { Link } from 'react-scroll';
import imgPlaceholder from '../../../assets/images/ImgPlaceholder.png';

function MenuScrollItem({
  category,
  handleExpandCategory,
  menuTranslationsEnabled,
  menuLanguage,
  isActive,
}) {
  const [imageCover, setImageCover] = useState();
  const [active, setActive] = useState(false);
  const ref = useRef();

  function handleExpandsCategory(el) {
    handleExpandCategory(el);
  }

  const changeCover = useCallback(() => {
    const photo = category?.products.filter(
      product => product.image?.url_thumb || product.image?.url,
    );

    if (category.image) {
      setImageCover(category.image?.url_thumb || category.image.url);
    } else if (photo.length > 0) {
      setImageCover(photo[0]?.image?.url_thumb || photo[0]?.image?.url);
    } else {
      setImageCover(imgPlaceholder);
    }
  }, [category]);

  useEffect(() => {
    changeCover();
  }, [changeCover]);

  return (
    <Link
      className="styled-links"
      to={category.name}
      spy
      smooth
      duration={0}
      offset={-185}
    >
      <CategoryList onClick={el => handleExpandsCategory(category.name)}>
        <CategoryThumbnailLink
          showing={isActive}
          ref={ref}
          style={{
            backgroundImage: `url(${imageCover || ''})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          actives={active}
        />
        <p>
          {menuTranslationsEnabled && menuLanguage === 'english'
            ? category?.translations?.en?.name
              ? category?.translations?.en?.name
              : category.name
            : category.name}
        </p>
      </CategoryList>
    </Link>
  );
}

export default MenuScrollItem;
