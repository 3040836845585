import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
`;

export const ErrorSpan = styled.span`
  position: absolute;
  left: 0;
  bottom: -22px;
  width: fit-content;

  color: #c8131b !important;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  animation: shake 0.3s;

  @keyframes shake {
    0% {
      transform: translateX(-3px);
    }
    33% {
      transform: translateX(3px);
    }
    66% {
      transform: translateX(-3px);
    }
    100% {
      transform: translateX(3px);
    }
  }
`;

export const SvgContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 25px;
  height: 40px;
  width: 24px;
  background-color: #fff;
  cursor: pointer;

  svg {
    color: #2ec9b7;
  }
`;
