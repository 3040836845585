import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft, FiClock } from 'react-icons/fi';

import { FaCalendarAlt, FaDolly, FaMotorcycle } from 'react-icons/fa';
import Modal from 'react-modal';

import { useAuth } from '../../../context/auth';
import {
  Container,
  Content,
  Header,
  ButtonDeliveryType,
  ButtonGroup,
  Title,
  DeliveryTime,
  DeliveryInfo,
  IconDiv,
  ModalTitle,
  CancelButton,
  ConfirmButton,
  ModalFooter,
} from './styles';

import api from '../../../services/api';
import { useCart } from '../../../context/cart';

const DeliveryType = () => {
  const history = useHistory();
  const {
    setUserDeliveryType,
    restaurantWithdrawalTime,
    restaurantDeliveryTime,
    isDeliveryByDistance,
    restaurantAcceptsWithdrawal,
    restaurantAcceptsDelivery,
    restaurantDeliveryActive,
    restaurantWithdrawalActive,
    setIsScheduling,
    orderSchedulingActive,
    restaurantId,
    restaurantDeliveryMinimumPrice,
    restaurantWithdrawallMinimumPrice,
    restaurantName,
  } = useAuth();
  const { cart } = useCart();
  const totalProductPrice = cart.reduce((TotalAccumulator, order) => {
    const totalCategories = order.complement_categories.reduce(
      (categoryAccumulator, category) => {
        if (category.additional) {
          if (category.more_expensive_only) {
            let totalComplements = 0;
            category.complements.forEach(complement => {
              if (
                totalComplements <
                parseFloat(
                  complement.delivery_price
                    ? complement.delivery_price
                    : complement.price,
                ) *
                  (parseInt(complement.amount) >= 1 ? 1 : 0)
              ) {
                totalComplements =
                  parseFloat(
                    complement.delivery_price
                      ? complement.delivery_price
                      : complement.price,
                  ) * (parseInt(complement.amount) >= 1 ? 1 : 0);
              }
            });
            return categoryAccumulator + totalComplements;
          }

          if (category.use_average) {
            const amountAverage = category.complements.reduce(
              (accum, curr) => accum + curr.amount,
              0,
            );

            const totalPriceAverage =
              category.complements
                .map(item => item)
                .reduce(
                  (acum, curr) =>
                    acum + (curr.delivery_price || curr.price) * curr.amount,
                  0,
                ) / amountAverage;

            return totalPriceAverage + categoryAccumulator;
          }
          const totalComplements = category.complements.reduce(
            (complementAccumulator, complement) => {
              return (
                complementAccumulator +
                (complement.delivery_price || complement.price) *
                  complement.amount
              );
            },
            0,
          );
          return (
            categoryAccumulator +
            totalComplements +
            (order.is_combo && category.cheapest
              ? parseFloat(category.cheapest) * category.minimum
              : 0)
          );
        }
        return categoryAccumulator;
      },
      0,
    );

    if (order.weight) {
      return (
        TotalAccumulator +
        (parseFloat(totalCategories) +
          parseFloat((order.delivery_price || order.price) * order.weight)) *
          order.amount
      );
    }
    return (
      TotalAccumulator +
      (parseFloat(totalCategories) +
        parseFloat(order.delivery_price || order.price)) *
        order.amount
    );
  }, 0);

  const [isModalMinimumErrorOpened, setIsModalMinimumErrorOpened] = useState(
    false,
  );

  function toggleModalMinimumError() {
    setIsModalMinimumErrorOpened(!isModalMinimumErrorOpened);
  }

  const [minimumType, setMinimumType] = useState(false);

  function handleConfirmDelivery() {
    setMinimumType('delivery');
    if (
      Number(restaurantDeliveryMinimumPrice) > 0 &&
      totalProductPrice < Number(restaurantDeliveryMinimumPrice)
    ) {
      toggleModalMinimumError();
    } else {
      setUserDeliveryType('delivery');
      setIsScheduling(false);

      if (isDeliveryByDistance.toString() === 'true') {
        history.push('/app/search-address-by-distance-delivery');
      } else {
        history.push('/app/search-address-delivery');
      }
    }
  }

  function handleConfirmWithdrawal() {
    setMinimumType('withdrawal');
    if (
      Number(restaurantWithdrawallMinimumPrice) > 0 &&
      totalProductPrice < Number(restaurantWithdrawallMinimumPrice)
    ) {
      toggleModalMinimumError();
    } else {
      setUserDeliveryType('withdrawal');
      setIsScheduling(false);

      history.push('/app/payments-delivery');
    }
  }

  function handleConfirmScheduledDelivery() {
    setMinimumType('delivery');
    if (
      Number(restaurantDeliveryMinimumPrice) > 0 &&
      totalProductPrice < Number(restaurantDeliveryMinimumPrice)
    ) {
      toggleModalMinimumError();
    } else {
      setUserDeliveryType('delivery');
      setIsScheduling(true);

      if (isDeliveryByDistance.toString() === 'true') {
        history.push('/app/search-address-by-distance-delivery');
      } else {
        history.push('/app/search-address-delivery');
      }
    }
  }

  function handleConfirmScheduledWithdrawal() {
    setMinimumType('withdrawal');
    if (
      Number(restaurantWithdrawallMinimumPrice) > 0 &&
      totalProductPrice < Number(restaurantWithdrawallMinimumPrice)
    ) {
      toggleModalMinimumError();
    } else {
      setUserDeliveryType('withdrawal');
      setIsScheduling(true);

      history.push('/app/schedule?type=withdrawal');
    }
  }

  // hide schedule options if no days are available
  const [deliveryScheduleOff, setDeliveryScheduleOff] = useState(false);
  const [withdrawalScheduleOff, setWithdrawalScheduleOff] = useState(false);

  const getRestaurantConfigs = useCallback(async () => {
    try {
      const response = await api.get(
        `/client/order-scheduling/${restaurantId}`,
      );

      setWithdrawalScheduleOff(
        response.data.withdrawal_active_days === 'fffffff',
      );
      setDeliveryScheduleOff(response.data.active_days === 'fffffff');
    } catch (err) {
      console.log('getRestaurantConfigs error: ', err);
    }
  }, [restaurantId]);

  useEffect(() => {
    getRestaurantConfigs();
  }, [getRestaurantConfigs]);

  Modal.setAppElement('#root');
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  return (
    <Container>
      <Modal
        isOpen={isModalMinimumErrorOpened}
        onRequestClose={toggleModalMinimumError}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>
            Seu pedido está abaixo do valor mínimo. Insira mais itens no
            carrinho para prosseguir.
          </p>

          {minimumType === 'delivery' && restaurantDeliveryMinimumPrice > 0 && (
            <>
              <span>Valor mínimo: </span>
              <span style={{ fontWeight: 'bold' }}>
                {`R$ ${restaurantDeliveryMinimumPrice}`}
              </span>
            </>
          )}

          {minimumType === 'withdrawal' &&
            restaurantWithdrawallMinimumPrice > 0 && (
              <>
                <span>Valor mínimo: </span>
                <span style={{ fontWeight: 'bold' }}>
                  {`R$ ${restaurantWithdrawallMinimumPrice}`}
                </span>
              </>
            )}
        </ModalTitle>
        <ModalFooter>
          <CancelButton onClick={toggleModalMinimumError}>
            Cancelar
          </CancelButton>
          <ConfirmButton
            onClick={() => {
              history.push(`/${restaurantName}`);
            }}
          >
            Ok, irei inserir
          </ConfirmButton>
        </ModalFooter>
      </Modal>
      <Content>
        <Header>
          <FiArrowLeft
            onClick={() => {
              history.push('/app/cart-delivery');
            }}
            color="rgba(251, 74, 32, 1)"
          />
          <h1>Método de Retirada</h1>
        </Header>

        <Title>Escolha abaixo o método de retirada.</Title>

        <ButtonGroup>
          {restaurantAcceptsDelivery.toString() === 'true' &&
            restaurantDeliveryActive.toString() === 'true' && (
              <ButtonDeliveryType onClick={handleConfirmDelivery}>
                <IconDiv>
                  <FaMotorcycle size={25} />
                </IconDiv>
                <DeliveryInfo>
                  <p style={{ width: 'fit-content' }}>Delivery</p>
                  <DeliveryTime>
                    {restaurantDeliveryTime > 0 && (
                      <>
                        <FiClock />
                        <span>{`Aprox. ${restaurantDeliveryTime} minutos`}</span>
                      </>
                    )}
                  </DeliveryTime>
                </DeliveryInfo>
              </ButtonDeliveryType>
            )}

          {restaurantAcceptsWithdrawal.toString() === 'true' &&
            restaurantWithdrawalActive.toString() === 'true' && (
              <ButtonDeliveryType onClick={handleConfirmWithdrawal}>
                <IconDiv>
                  <FaDolly size={25} />
                </IconDiv>
                <DeliveryInfo>
                  <p style={{ width: 'fit-content' }}>Retirar no balcão</p>
                  <DeliveryTime>
                    {restaurantWithdrawalTime > 0 && (
                      <>
                        <FiClock />
                        <span>{`Aprox. ${restaurantWithdrawalTime} minutos`}</span>
                      </>
                    )}
                  </DeliveryTime>
                </DeliveryInfo>
              </ButtonDeliveryType>
            )}

          {orderSchedulingActive && (
            <>
              {restaurantAcceptsDelivery && !deliveryScheduleOff && (
                <ButtonDeliveryType onClick={handleConfirmScheduledDelivery}>
                  <IconDiv>
                    <FaMotorcycle size={30} style={{ paddingRight: 5 }} />
                    <FaCalendarAlt
                      size={10}
                      style={{ position: 'absolute', top: 12, right: 12 }}
                    />
                  </IconDiv>
                  <DeliveryInfo>
                    <p style={{ width: 'fit-content' }}>Agendamento Delivery</p>
                  </DeliveryInfo>
                </ButtonDeliveryType>
              )}

              {restaurantAcceptsWithdrawal && !withdrawalScheduleOff && (
                <ButtonDeliveryType onClick={handleConfirmScheduledWithdrawal}>
                  <IconDiv size={30} style={{ paddingRight: 5 }}>
                    <FaDolly size={20} />
                    <FaCalendarAlt
                      size={10}
                      style={{ position: 'absolute', top: 12, right: 12 }}
                    />
                  </IconDiv>
                  <DeliveryInfo>
                    <p style={{ width: 'fit-content' }}>Agendamento Retirada</p>
                  </DeliveryInfo>
                </ButtonDeliveryType>
              )}
            </>
          )}
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default DeliveryType;
