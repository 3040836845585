import styled from 'styled-components';

export const StoreInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 48px;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  span {
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
`;
