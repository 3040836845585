import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  min-height: 100vh;
  width: 100%;
  max-width: 410px;
`;

export const Content = styled.div`
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 80px;
`;

export const ModalTitle = styled.div`
  color: #3d3d3d;
  p {
    text-align: left;
    font-weight: bold;
  }

  font-family: 'poppins';
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: space-between;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 14px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
  font-weight: bold;
  width: 50%;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 14px;
  font-size: 14px;
  color: #fff;
  background: #fe1933;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
  width: 50%;
  font-weight: bold;
`;

export const Header = styled.header`
  position: fixed;
  width: 100%;
  max-width: 410px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const ButtonGroup = styled.div`
  width: 100%;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  p {
    margin: 0;
  }
`;

export const ButtonDeliveryType = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 80px;
  background: #fff;
  box-shadow: 0 0 15px #33333320;
  border: 0;
  border-radius: 5px;

  & + div {
    margin-top: 16px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 26px;
  }

  p {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
`;

export const DeliveryInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 250px;
  height: 90%;
  margin-left: 10%;
`;

export const Title = styled.p`
  margin-top: 86px;
`;

export const DeliveryTime = styled.div`
  display: flex;

  align-items: center;
  height: 35px;

  span {
    margin-left: 4px;
    font-weight: normal;
  }
`;

export const IconDiv = styled.div`
  height: 58px;
  width: 58px;
  border-radius: 5px;
  border: 1px solid #ff1c32;
  color: #ff1c32;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 20px;
`;
