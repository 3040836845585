import React, { useContext, useEffect, useState } from 'react';
import FormCard from '../../FormCard';
import registerConfirmation from '../../../../assets/images/register-confirmation.svg';
import { ConfirmationContainer } from './styles';
import { ClubFormContext } from '../../../../context/clubForm';
import { Button } from 'ui-kit-takeat';
import { toast } from 'react-toastify';

export const Confirmation = () => {
  const {
    getFormValue,
    updateForm,
    setFormStep,
    toggleModalError,
  } = useContext(ClubFormContext);

  const searchParams = new URLSearchParams(window.location.search);
  const phone = searchParams.get('phone');
  const formPhone = getFormValue('phone');

  useEffect(() => {
    if (phone) {
      updateForm('phone', phone);
    }
  }, []);

  return (
    <>
      <FormCard.Header>Confirmação</FormCard.Header>
      <FormCard.Body>
        <ConfirmationContainer>
          <img src={registerConfirmation} />
          <p>
            Por favor, verifique se o número de telefone inserido ao fazer o
            pedido está correto
          </p>
          <span>{formPhone}</span>
        </ConfirmationContainer>
      </FormCard.Body>
      <FormCard.Buttons vertical>
        <Button
          title="É o meu número"
          buttonColor="#2ec9b7"
          onClick={() => setFormStep(state => state + 1)}
        />
        <Button
          title="NÃO é o meu número"
          buttonColor="#c8131b"
          inverted
          onClick={() => toggleModalError()}
        />
      </FormCard.Buttons>
    </>
  );
};
