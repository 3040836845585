import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import ErroLocation from '../../assets/images/erroLocation.png';

import { Container, Content, Footer } from './styles';

const LocationError = () => {
  const { restaurantId } = useAuth();
  const history = useHistory();

  function handleNavigate() {
    history.push(`/menu/${restaurantId}`);
  }
  return (
    <Container>
      <Content>
        <img src={ErroLocation} alt="Ilustracao de erro de geolocalizacao" />
        <h1>Não encontramos sua localização</h1>
        <p>
          Ajuste o seu navegador para <span>permitir sua localização</span>,
          precisamos dela para o funcionamento seguro do sistema. Caso tenha alguma dúvida quanto a este procedimento,
          chame o gerente ou o garçom para te auxiliar.
        </p>
      </Content>
      <Footer>
        <button onClick={handleNavigate}>Ok, entendi</button>
      </Footer>
    </Container>
  );
};

export default LocationError;
