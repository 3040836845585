import React, { useState } from 'react';
import englishFlag from '../../assets/images/englishFlag.svg';
import brasilianFlag from '../../assets/images/portugueseFlag.svg';
import { BorderImg, Container, FlagImg } from './styles';
import { useAuth } from '../../context/auth';

function FlagsArea({ menu }) {
  const { setMenuTranslationsLanguage, menuTranslationsLanguage } = useAuth();

  const [isActive, setIsActive] = useState(menuTranslationsLanguage.toString());
  return (
    <Container menu={menu}>
      <BorderImg
        active={isActive.toString() === 'portuguese'}
        onClick={() => {
          setIsActive('portuguese');
          setMenuTranslationsLanguage('portuguese');
        }}
      >
        <FlagImg
          src={brasilianFlag}
          alt="bandeira do brasil pra selecionar linguagem do cardapio em português"
        />
      </BorderImg>

      <BorderImg
        active={isActive.toString() === 'english'}
        onClick={() => {
          setIsActive('english');
          setMenuTranslationsLanguage('english');
        }}
      >
        <FlagImg
          src={englishFlag}
          alt="bandeira do eua pra selecionar linguagem do cardapio em ingles"
        />
      </BorderImg>
    </Container>
  );
}

export default FlagsArea;
