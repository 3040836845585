import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import { Progress } from 'reactstrap';
import formatCompleteDate from '../../../../utils/formatCompleteDate';
import { useAuth } from '../../../../context/auth';

import {
  Container,
  Content,
  Header,
  OrderDetails,
  OrderDetailsHeader,
  OrderDetailsBody,
  OrderDetailsFooter,
  OrderStatus,
  OrderTime,
  OrderKey,
  Orders,
  OrderProduct,
  OrderPrice,
  OrderDiscount,
  OrderDiscountArea,
  ButtonOrders,
} from './styles';

const OrdersDetails = () => {
  const location = useLocation();
  const { order: product } = location.state;
  const history = useHistory();
  const { formatValue } = useAuth();

  function handleNavigate() {
    history.goBack();
  }

  function searchStatusText(status) {
    switch (status) {
      case 'waiting_payment':
        return 'Aguardando pagamento';
        break;

      case 'ready':
        return 'Saiu pra entrega';
        break;

      case 'accepted':
        return 'Preparando o pedido';
        break;

      case 'pending':
        return 'Aguardando o restaurante';
        break;

      case 'finished':
        return 'Pedido entregue';
        break;

      case 'canceled':
        return 'Pedido cancelado';
        break;
    }
  }

  function searchStatusColor(status) {
    switch (status) {
      case 'waiting_payment':
        return 'warning';
        break;

      case 'ready':
        return 'success';
        break;

      case 'accepted':
        return 'success';
        break;

      case 'pending':
        return 'warning';
        break;

      case 'finished':
        return 'success';
        break;

      case 'canceled':
        return 'warning';
        break;
    }
  }

  function searchTextColor(status) {
    switch (status) {
      case 'waiting_payment':
        return 'orange';
        break;

      case 'ready':
        return 'green';
        break;

      case 'finished':
        return 'green';
        break;

      case 'canceled':
        return 'red';
        break;

      case 'accepted':
        return 'green';
        break;

      case 'pending':
        return 'orange';
        break;
    }
  }

  function searchStatusValue(status) {
    switch (status) {
      case 'waiting_payment':
        return '25';
        break;

      case 'ready':
        return '75';
        break;

      case 'accepted':
        return '50';
        break;

      case 'pending':
        return '25';
        break;

      case 'canceled':
        return '100';
        break;

      case 'finished':
        return '100';
        break;
    }
  }

  return (
    <Container>
      <Content>
        <Header>
          <h1>Detalhes do Pedido</h1>
        </Header>

        <OrderDetails>
          <OrderDetailsHeader>
            <p>{product.fantasy_name}</p>
          </OrderDetailsHeader>
          <OrderDetailsBody>
            <OrderKey>{product.attendance_password ? `Senha: ${product.attendance_password}` : ''}</OrderKey>
            <OrderTime>
              {`Pedido feito em ${formatCompleteDate(product.start_time)}`}
            </OrderTime>

            <Orders>
              {product.orders.map(item => (
                <React.Fragment key={item.id}>
                  <OrderProduct>
                    <p>{`${item.amount}x ${item.product.name}`}</p>
                    <p>{formatValue(Number(item.total_price))}</p>
                  </OrderProduct>
                </React.Fragment>
              ))}
            </Orders>

            {product.discount > 0 && (
              <OrderDiscountArea>
                <p>Descontos</p>
                <OrderDiscount>{formatValue(product.discount)}</OrderDiscount>
              </OrderDiscountArea>
            )}

            {product.delivery_tax_price > 0 && (
              <OrderPrice>
                <p>Taxa</p>
                <p>{formatValue(product.delivery_tax_price)}</p>
              </OrderPrice>
            )}

            {product.will_receive_sms ? (
              <OrderPrice>
                <p>Total + sms</p>
                <p>
                  {formatValue(
                    Number(product.total_price_with_sms) +
                    Number(product.delivery_tax_price),
                  )}
                </p>
              </OrderPrice>
            ) : (
              <OrderPrice>
                <p>Total</p>
                <p>
                  {formatValue(
                    Number(product.total_price) +
                    Number(product.delivery_tax_price),
                  )}
                </p>
              </OrderPrice>
            )}
          </OrderDetailsBody>

          <OrderDetailsFooter>
            <OrderStatus status={product.order_status}>
              {product.order_status !== 'canceled' && (
                <Progress
                  animated
                  color={searchStatusColor(product.order_status)}
                  value={searchStatusValue(product.order_status)}
                  style={{ height: 10 }}
                />
              )}

              <p style={{ color: searchTextColor(product.order_status) }}>
                {product.with_withdrawal && product.order_status === 'ready'
                  ? 'Pronto pra retirada'
                  : searchStatusText(product.order_status)}
              </p>

              {product?.motoboy && (
                <p style={{ fontSize: '14px' }}>
                  Entregador: {product.motoboy?.name}
                </p>
              )}


              {/* {product.order_status === 'canceled' && <p>Pedido cancelado</p>}
              {product.order_status === 'pending' && (
                <p>Aguardando restaurante</p>
              )}
              {product.order_status === 'accepted' && (
                <p>Preparando o pedido</p>
              )}
              {product.order_status === 'ready' && product.with_withdrawal && (
                <p>Pronto para retirada</p>
              )}
              {product.order_status === 'ready' && !product.with_withdrawal && (
                <p>Saiu pra entrega</p>
              )}
              {product.order_status === 'finished' && <p>Pedido entregue.</p>}

              {product.order_status === 'payment_pending' && (
                <p>Pagamento pendente</p>
              )}
              {product.order_status === 'chargeback' && (
                <p>Pedido cancelado - Pagamento estornado</p>
              )} */}
            </OrderStatus>
          </OrderDetailsFooter>
        </OrderDetails>


        <ButtonOrders onClick={handleNavigate}>
          <FiArrowLeft />
          Voltar para pedidos
        </ButtonOrders>
      </Content>
    </Container>
  );
};

export default OrdersDetails;
