import styled from 'styled-components';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  p {
    color: #6c6c6c;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  span {
    border-bottom: 1px solid #cdcdcd;
    padding: 8px 58px;
    color: #6c6c6c;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  img {
    width: 50%;
  }
`;
