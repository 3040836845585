import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Map, Marker, Draggable, ZoomControl } from 'pigeon-maps';
import { Hyperlink } from 'ui-kit-takeat';
import Modal from 'react-modal';
import {
  Modal as ModalPersonal,
  ModalHeader,
  ModalBody as ModalBodyStrap,
  ModalFooter as ModalFooterStrap,
} from 'reactstrap';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { FiAlertCircle, FiArrowLeft } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import axios from 'axios';
import api from '../../../services/api';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErrors';
// import apiIbge from '../../services/apiIbge';

import { useAuth } from '../../../context/auth';

import {
  Container,
  Content,
  Header,
  ButtonOrders,
  Text,
  AddressField,
  StreetInputField,
  InputField,
  UserAddress,
  ButtonGroup,
  ChangeAddress,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  CancelButton,
  DesableCepButton,
  ModalBody,
  // ValidAddressTitle,
} from './styles';

const SearchAddressByDistance = () => {
  const {
    restaurantId,
    setUserAddressId,
    setUserAddress,
    userAddress,
    setRestaurantDeliveryTax,
    restaurantAddress,
    userLogout,
    changeUserInfo,
    isScheduling,
    setRestaurantDeliveryTimeAddress,
    restaurantRequiresUserCEP,
  } = useAuth();
  const history = useHistory();
  const formRef = useRef(null);
  const formConfirmAddressRef = useRef(null);

  // modal style
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [modalErrorApi, setModalErrorApi] = useState(false);
  const [modalErrorApiToken, setModalErrorApiToken] = useState(false);
  const [validCep, setValidCep] = useState(true);
  const [
    isModalConfirmAddressOpened,
    setIsModalConfirmAddressOpened,
  ] = useState(false);
  const [modalPhrase, setModalPhrase] = useState('');
  const [editAddressInput, setEditAddressInput] = useState(false);
  const [coordinates, setCoordinates] = useState([]);

  Modal.setAppElement('#root');

  const [, setCount] = useState();
  const [selectedState, setSelectedState] = useState(restaurantAddress.state);
  const [disableCep, setDisableCep] = useState(false);

  const maskOnlyNumbers = value => {
    return value.replace(/\D/g, '');
  };

  // const getStatesData = useCallback(async () => {
  //   try {
  //     const response = await apiIbge.get('/localidades/estados');
  //     const parsedStates = response.data.map((item) => {
  //       return {
  //         label: item.nome,
  //         value: item.sigla,
  //       };
  //     });
  //     setStates(parsedStates);
  //   } catch (err) {
  //     alert('Erro ao carregar informações');
  //   }
  // }, []);

  // const getCitiesData = useCallback(async () => {
  //   try {
  //     console.log(selectedState);
  //     const response = await apiIbge.get(
  //       `/localidades/estados/${selectedState.value}/municipios`
  //     );

  //     const parsedCities = response.data.map((item) => {
  //       return {
  //         label: item.nome,
  //         value: item.nome,
  //       };
  //     });
  //     setCities(parsedCities);
  //   } catch (err) {
  //     alert('Erro ao carregar informações');
  //   }
  // }, [selectedState]);

  function toggleModalConfirmAddress() {
    setIsModalConfirmAddressOpened(!isModalConfirmAddressOpened);
    setEditAddressInput(false);
  }

  function handleNavigate() {
    history.push('/app/delivery-type');
  }

  function handleChangeAddress() {
    setUserAddress('');

    const aleatoryId = uuid();
    setCount(aleatoryId);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeErrorModal() {
    setModalErrorIsOpen(false);
  }

  function closeModalErrorApi() {
    setModalErrorApi(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openErrorModal() {
    setModalErrorIsOpen(true);
  }

  function openModalErrorApi() {
    setModalErrorApi(true);
  }

  function closeModalWithChange() {
    setUserAddress('');

    const aleatoryId = uuid();
    setCount(aleatoryId);

    setIsOpen(false);
  }

  function handleDisableCep() {
    setDisableCep(!disableCep);
  }

  function toggleModalErrorApiToken() {
    setModalErrorApiToken(!modalErrorApiToken);
  }

  function handleNavigateLogin() {
    toggleModalErrorApiToken();
    history.push('/app/cart-delivery');
  }

  async function handleValidateAddress() {
    try {
      const response = await api.post(
        '/client/delivery-addresses/validate-and-save',
        {
          country: 'BR',
          state: userAddress.state || '',
          city: userAddress.city,
          neighborhood: userAddress.neighborhood,
          street: userAddress.street,
          number: userAddress.number,
          complement: userAddress.complement || '',
          reference: userAddress.reference,
          zip_code: userAddress.zip_code || '',
          restaurant_id: restaurantId,
        },
      );

      if (response.data.valid_address === true) {
        setRestaurantDeliveryTimeAddress(response.data.address.delivery_time);
        setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
        setEditAddressInput(false);

        setUserAddressId(response.data.address.id);

        if (isScheduling) {
          history.push('/app/schedule?type=delivery');
        } else {
          history.push('/app/payments-delivery');
        }
      } else {
        openModal();
        setEditAddressInput(false);
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'token_not_sent':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'buyer_outside_bounds':
          case 'delivery_max_distance':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            break;

          case 'delivery_no_distance_found':
            setModalPhrase(
              'Há inconsistência no cadastro de delivery. Favor comunicar ao restaurante',
            );
            openModalErrorApi();
            break;

          case 'google_api_status_not_ok':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            break;

          case 'address_not_found':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            break;

          case 'zip_code_not_found':
            setModalPhrase('Infelizmente não encontramos este CEP informado.');
            openModalErrorApi();
            break;

          default:
            openModalErrorApi();
        }
      }
    }
  }

  async function handleValidateAddressAfterSave() {
    try {
      if (editAddressInput) {
        const response = await api.post(
          '/client/delivery-addresses/validate-and-save',
          {
            country: 'BR',
            state: userAddress.state || '',
            city: userAddress.city,
            neighborhood: userAddress.neighborhood,
            street: userAddress.street,
            number: userAddress.number,
            complement: userAddress.complement || '',
            reference: userAddress.reference,
            zip_code: userAddress.zip_code || '',
            restaurant_id: restaurantId,
          },
        );

        if (response.data.valid_address === true) {
          setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
          setRestaurantDeliveryTimeAddress(response.data.address.delivery_time);

          if (isScheduling) {
            history.push('/app/schedule?type=delivery');
          } else {
            history.push('/app/payments-delivery');
          }
          setCoordinates([]);
        } else {
          toggleModalConfirmAddress();
          openModal();
          setUserAddressId('');
          setEditAddressInput(false);
        }
        setCoordinates([]);
      } else {
        if (isScheduling) {
          history.push('/app/schedule?type=delivery');
        } else {
          history.push('/app/payments-delivery');
        }
        setCoordinates([]);
      }
    } catch (err) {
      if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'token_not_sent':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'buyer_outside_bounds':
          case 'delivery_max_distance':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'delivery_no_distance_found':
            setModalPhrase(
              'Há inconsistência no cadastro de delivery. Favor comunicar ao restaurante',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'google_api_status_not_ok':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'address_not_found':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'zip_code_not_found':
            setModalPhrase('Infelizmente não encontramos este CEP informado.');
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);
            break;

          default:
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);
        }
      }
    }
  }

  const [confirmAddress, setConfirmAddress] = useState({});

  async function handleRegisterAddress(data) {
    try {
      if (formRef.current) {
        formRef.current.setErrors({});
      }
      if (restaurantRequiresUserCEP) {
        const schema = Yup.object().shape({
          street: Yup.string().required('Nome obrigatório'),
          number: Yup.string()
            .matches(/^[0-9]+$/, 'Somente números')
            .required('Número obrigatório'),
          city: Yup.string().required('Cidade é obrigatória'),
          neighboorhood: Yup.string().required('Bairro obrigatório'),
          zip_code: Yup.string()
            .matches(/^\d{5}-\d{3}$/, 'CEP deve estar no formato 99999-999')
            .required('CEP é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } else {
        const schema = Yup.object().shape({
          street: Yup.string().required('Nome obrigatório'),
          number: Yup.string()
            .matches(/^[0-9]+$/, 'Somente números')
            .required('Número obrigatório'),
          city: Yup.string().required('Cidade é obrigatória'),
          neighboorhood: Yup.string().required('Bairro obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      if (!validCep) {
        toast.error('Por favor insira um CEP válido');
        return;
      }

      const res = await api.post(
        '/client/delivery-addresses/validate-and-save',
        {
          country: 'BR',
          state: selectedState,
          city: data.city,
          neighborhood: data.neighboorhood,
          street: data.street,
          number: data.number,
          complement: data.complement || '',
          reference: data.reference,
          zip_code: data.zip_code || '',
          restaurant_id: restaurantId,
        },
      );

      if (res.data.valid_address === true) {
        setRestaurantDeliveryTax(res.data.address.delivery_tax_price);

        setUserAddressId(res.data.address.id);

        setConfirmAddress(data);
        toggleModalConfirmAddress();

        const { latitude, longitude } = res.data.address;

        setCoordinates([latitude, longitude]);

        setUserAddress({
          country: 'BR',
          state: selectedState,
          city: data.city,
          neighborhood: data.neighboorhood,
          street: data.street,
          number: data.number,
          complement: data.complement || '',
          reference: data.reference || '',
          zip_code: data.zip_code || '',
        });

        // history.push('/app/payments-delivery');
      } else {
        openModal();
      }

      //   const response = await api.post(
      //     'client/restaurant-delivery-address/validate',
      //     {
      //       restaurant_id: restaurantId,
      //       delivery_address: {
      //         city: data.city || '',
      //         country: 'BR',
      //         state: selectedState || '',
      //         neighborhood: data.neighboorhood || '',
      //         zip_code: data.zip_code || '',
      //       },
      //     },
      //   );

      //   if (response.data.valid_address === true) {
      //     setRestaurantDeliveryTax(response.data.address.delivery_tax_price);

      //     setUserAddressId(res.data.id);

      //     setUserAddress({
      //       country: 'BR',
      //       state: selectedState,
      //       city: data.city,
      //       neighborhood: data.neighboorhood,
      //       street: data.street,
      //       number: data.number,
      //       complement: data.complement || '',
      //       reference: data.reference || '',
      //       zip_code: data.zip_code || '',
      //     });

      //     history.push('/app/payments-delivery');
      //   } else {
      //     openModal();
      //   }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef.current) {
          formRef.current.setErrors(errors);
        }
      } else if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'token_setConfirmAddressnot_sent':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            break;

          case 'buyer_outside_bounds':
          case 'delivery_max_distance':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            break;

          case 'google_api_status_not_ok':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            break;

          case 'delivery_no_distance_found':
            setModalPhrase(
              'Há inconsistência no cadastro de delivery. Favor comunicar ao restaurante',
            );
            openModalErrorApi();
            break;

          case 'address_not_found':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            break;

          case 'zip_code_not_found':
            setModalPhrase('Infelizmente não encontramos este CEP informado.');
            openModalErrorApi();
            break;

          default:
            setModalPhrase(
              'Houve um erro ao cadastrar seu endereço, verifique seus dados digitados.',
            );
            openModalErrorApi();
        }
      }
    }
  }

  async function handleValidateAddressAndSubmit(data) {
    try {
      if (formConfirmAddressRef.current) {
        formConfirmAddressRef.current.setErrors({});
      }
      if (restaurantRequiresUserCEP) {
        const schema = Yup.object().shape({
          street: Yup.string().required('Nome obrigatório'),
          number: Yup.string()
            .matches(/^[0-9]+$/, 'Somente números')
            .required('Número obrigatório'),
          city: Yup.string().required('Cidade é obrigatória'),
          neighboorhood: Yup.string().required('Bairro obrigatório'),
          zip_code: Yup.string()
            .matches(/^\d{5}-\d{3}$/, 'CEP deve estar no formato 99999-999')
            .required('CEP é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } else {
        const schema = Yup.object().shape({
          street: Yup.string().required('Nome obrigatório'),
          number: Yup.string()
            .matches(/^[0-9]+$/, 'Somente números')
            .required('Número obrigatório'),
          city: Yup.string().required('Cidade é obrigatória'),
          neighboorhood: Yup.string().required('Bairro obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      if (!validCep) {
        toast.error('Por favor insira um CEP válido');
        return;
      }

      const response = await api.post(
        '/client/delivery-addresses/validate-and-save',
        {
          country: 'BR',
          state: selectedState,
          city: data.city,
          neighborhood: data.neighboorhood,
          street: data.street,
          number: data.number,
          complement: data.complement || '',
          reference: data.reference,
          zip_code: data.zip_code || '',
          restaurant_id: restaurantId,
        },
      );

      if (response.data.valid_address === true) {
        setRestaurantDeliveryTax(response.data.address.delivery_tax_price);
        setRestaurantDeliveryTimeAddress(response.data.address.delivery_time);

        setUserAddressId(response.data.address.id);

        setUserAddress({
          country: 'BR',
          state: selectedState,
          city: data.city,
          neighborhood: data.neighboorhood,
          street: data.street,
          number: data.number,
          complement: data.complement || '',
          reference: data.reference || '',
          zip_code: data.zip_code || '',
        });

        if (isScheduling) {
          history.push('/app/schedule?type=delivery');
        } else {
          history.push('/app/payments-delivery');
        }
        setCoordinates([]);
      } else {
        openModal();
        setEditAddressInput(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formConfirmAddressRef.current) {
          formConfirmAddressRef.current.setErrors(errors);
        }
      } else if (!err.response.ok) {
        switch (err.response.data.errorType) {
          case 'invalid_token':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'token_setConfirmAddressnot_sent':
            userLogout();
            changeUserInfo();
            toggleModalErrorApiToken();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'buyer_outside_bounds':
          case 'delivery_max_distance':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'google_api_status_not_ok':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'delivery_no_distance_found':
            setModalPhrase(
              'Há inconsistência no cadastro de delivery. Favor comunicar ao restaurante',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'address_not_found':
            setModalPhrase(
              'Infelizmente não entregamos neste endereço informado.',
            );
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          case 'zip_code_not_found':
            setModalPhrase('Infelizmente não encontramos este CEP informado.');
            openModalErrorApi();
            toggleModalConfirmAddress();
            setCoordinates([]);

            break;

          default:
            setModalPhrase(
              'Houve um erro ao cadastrar seu endereço, verifique seus dados digitados.',
            );
            toggleModalConfirmAddress();
            openModalErrorApi();
            setCoordinates([]);
        }
      }
    }
  }

  async function handleSearchCep(e) {
    const regex = /^\d{5}-\d{3}$/;
    if (regex.test(e.target.value)) {
      const cep = e.target.value.replace('-', '').replace('.', '');

      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      // const response = await api.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        const streetInput = formRef.current.getFieldRef('street');
        const neighboorhoodInput = formRef.current.getFieldRef('neighboorhood');
        const cityInput = formRef.current.getFieldRef('city');
        streetInput.value = response.data.logradouro;
        neighboorhoodInput.value = response.data.bairro;
        cityInput.value = response.data.localidade;
        setSelectedState(response.data.uf);
        setValidCep(true);
      } else {
        const streetInput = formRef.current.getFieldRef('street');
        const neighboorhoodInput = formRef.current.getFieldRef('neighboorhood');
        const cityInput = formRef.current.getFieldRef('city');
        setValidCep(false);
        streetInput.value = '';
        neighboorhoodInput.value = '';
        cityInput.value = '';
      }
    }
  }

  async function handleSearchConfirmCep(e) {
    const regex = /^\d{5}-\d{3}$/;
    if (regex.test(e.target.value)) {
      const cep = e.target.value.replace('-', '').replace('.', '');

      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      // const response = await api.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        const streetInput = formConfirmAddressRef.current.getFieldRef('street');
        const neighboorhoodInput = formConfirmAddressRef.current.getFieldRef(
          'neighboorhood',
        );
        const cityInput = formConfirmAddressRef.current.getFieldRef('city');
        streetInput.value = response.data.logradouro;
        neighboorhoodInput.value = response.data.bairro;
        cityInput.value = response.data.localidade;
        setSelectedState(response.data.uf);
        setValidCep(true);
      } else {
        const streetInput = formConfirmAddressRef.current.getFieldRef('street');
        const neighboorhoodInput = formConfirmAddressRef.current.getFieldRef(
          'neighboorhood',
        );
        const cityInput = formConfirmAddressRef.current.getFieldRef('city');
        setValidCep(false);
        streetInput.value = '';
        neighboorhoodInput.value = '';
        cityInput.value = '';
      }
    }
  }

  function toggleEditAddressInput() {
    setEditAddressInput(!editAddressInput);
  }

  async function handleSetCoordinates(data) {
    setCoordinates([data[0], data[1]]);

    const response = await api.get('/client/delivery-addresses/location', {
      params: {
        latitude: data[0],
        longitude: data[1],
      },
    });

    setConfirmAddress(response.data);

    const streetInput = formConfirmAddressRef.current.getFieldRef('street');
    const neighboorhoodInput = formConfirmAddressRef.current.getFieldRef(
      'neighboorhood',
    );
    const cityInput = formConfirmAddressRef.current.getFieldRef('city');
    const numberInput = formConfirmAddressRef.current.getFieldRef('number');
    const cepInput = formConfirmAddressRef.current.getFieldRef('zip_code');
    streetInput.value = response.data.street;
    neighboorhoodInput.value = response.data.neighborhood;
    cityInput.value = response.data.city;
    numberInput.value = response.data.number;
    cepInput.value = response.data.zip_code;
  }

  return (
    <Container>
      <ModalPersonal
        isOpen={isModalConfirmAddressOpened}
        toggle={toggleModalConfirmAddress}
      >
        <Form
          ref={formConfirmAddressRef}
          onSubmit={handleValidateAddressAndSubmit}
          initialData={confirmAddress}
        >
          <ModalHeader>
            <p>Confirme seu endereço.</p>
          </ModalHeader>

          <ModalBodyStrap>
            {coordinates.length > 0 && (
              <Map height={300} defaultCenter={coordinates} defaultZoom={17}>
                {!editAddressInput && (
                  <Marker width={50} anchor={coordinates} />
                )}

                {editAddressInput && (
                  <Draggable
                    offset={[10, 10]}
                    onDragEnd={data => handleSetCoordinates(data)}
                    anchor={coordinates}
                  >
                    <Marker width={50} />
                  </Draggable>
                )}
              </Map>
            )}

            {editAddressInput && (
              <AddressField style={{ width: '100%' }}>
                <p
                  style={{ color: 'red', display: validCep ? 'none' : 'block' }}
                >
                  <FiAlertCircle color="red" /> CEP inválido
                </p>

                <InputField disableCep={disableCep}>
                  <InputMask
                    mask="99999-999"
                    onChange={e => handleSearchConfirmCep(e)}
                    maskChar=""
                  >
                    <Input
                      type="text"
                      placeholder="Seu CEP ..."
                      name="zip_code"
                      autoComplete="off"
                    />
                  </InputMask>
                </InputField>

                {!restaurantRequiresUserCEP ? (
                  <DesableCepButton type="button" onClick={handleDisableCep}>
                    {disableCep ? 'Quero informar meu CEP' : 'Não sei meu CEP'}
                  </DesableCepButton>
                ) : (
                  <Hyperlink
                    fontSize={12}
                    marginTop={8}
                    lineHeight="inherit"
                    fontFamily="Poppins"
                    fontWeight="bold"
                    color="#ff1814"
                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                  >
                    Não sei meu CEP
                  </Hyperlink>
                )}

                <StreetInputField>
                  <InputField>
                    <Input
                      type="text"
                      placeholder="Digite sua rua ..."
                      name="street"
                      id="street"
                      autoComplete="off"
                    />
                  </InputField>

                  <InputField>
                    <Input
                      type="text"
                      placeholder="Número ..."
                      name="number"
                      autoComplete="off"
                    />
                  </InputField>
                </StreetInputField>

                <InputField>
                  <Input
                    type="text"
                    placeholder="Bairro ..."
                    name="neighboorhood"
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <Input
                    type="text"
                    placeholder="Cidade ..."
                    name="city"
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <Input
                    type="text"
                    name="complement"
                    placeholder="Complemento ..."
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <Input
                    type="text"
                    name="reference"
                    placeholder="Referência ..."
                  />
                </InputField>
              </AddressField>
            )}
          </ModalBodyStrap>

          <ModalFooterStrap>
            {editAddressInput ? (
              <CancelButton
                type="button"
                onClick={() => {
                  toggleModalConfirmAddress();
                  setCoordinates([]);
                }}
              >
                Cancelar
              </CancelButton>
            ) : (
              <CancelButton type="button" onClick={toggleEditAddressInput}>
                Editar
              </CancelButton>
            )}

            {editAddressInput ? (
              <ConfirmButton type="submit">Confirmar</ConfirmButton>
            ) : (
              <ConfirmButton onClick={handleValidateAddressAfterSave}>
                Confirmar
              </ConfirmButton>
            )}
          </ModalFooterStrap>
        </Form>
      </ModalPersonal>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Infelizmente não entregamos no endereço informado.</p>
        </ModalTitle>

        <ModalFooter>
          <CancelButton onClick={closeModal}>Cancelar</CancelButton>
          <ConfirmButton onClick={closeModalWithChange}>
            Alterar meu endereço
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorApi}
        onRequestClose={closeModalErrorApi}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>{modalPhrase}</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={closeModalErrorApi}>
            Ok, irei verificar
          </ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorIsOpen}
        onRequestClose={closeErrorModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Favor preencher os dados corretamente.</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={closeErrorModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalErrorApiToken}
        onRequestClose={toggleModalErrorApiToken}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>
            Seu login expirou! Por segurança pedimos que faça login novamente,
            preenchendo seus dados. Iremos te direcionar para o login.
          </p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={handleNavigateLogin}>
            Ok, entendi
          </ConfirmButton>
        </ModalFooter>
      </Modal>
      {userAddress ? (
        <Content>
          <Header>
            <FiArrowLeft color="var(--primary)" onClick={handleNavigate} />
            <h1>Endereço Cadastrado</h1>
          </Header>

          <Text>
            <p>
              Você cadastrou esse endereço. Verifique se o restaurante atual
              entrega neste informado, ou altere o endereço.
            </p>
          </Text>

          <UserAddress>
            <p>
              {`${userAddress.street && `Logradouro: ${userAddress.street}`}, ${
                userAddress.number
              }`}
            </p>
            <p>
              {(userAddress.neighborhood &&
                `Bairro: ${userAddress.neighborhood}`) ||
                ''}
            </p>
            <p>
              {(userAddress.zip_code && `CEP: ${userAddress.zip_code}`) || ''}
            </p>
            <p>
              {(userAddress.complement &&
                `Complemento: ${userAddress.complement}`) ||
                ''}
            </p>
            <p>
              {(userAddress.reference &&
                `Referência: ${userAddress.reference}`) ||
                ''}
            </p>
            <p>{`${userAddress.city} - ${userAddress.state}`}</p>
          </UserAddress>

          <ButtonGroup>
            <ChangeAddress onClick={handleChangeAddress}>
              Alterar endereço
            </ChangeAddress>
          </ButtonGroup>

          <ButtonOrders onClick={handleValidateAddress}>
            Verificar e Prosseguir
          </ButtonOrders>
        </Content>
      ) : (
        <Content>
          <Header>
            <FiArrowLeft color="var(--primary)" onClick={handleNavigate} />
            <h1>Endereço para entrega</h1>
          </Header>

          <Text>
            <p>
              Verifique abaixo se temos disponibilidade para delivery em seu
              endereço.
            </p>
          </Text>

          <AddressField style={{ maxWidth: '410px' }}>
            <p style={{ color: 'red', display: validCep ? 'none' : 'block' }}>
              <FiAlertCircle color="red" /> CEP inválido
            </p>
            <Form ref={formRef} onSubmit={handleRegisterAddress}>
              <InputField disableCep={disableCep}>
                <InputMask
                  mask="99999-999"
                  onChange={e => handleSearchCep(e)}
                  maskChar=""
                >
                  <Input
                    type="text"
                    placeholder="Seu CEP ..."
                    name="zip_code"
                    autoComplete="off"
                  />
                </InputMask>
              </InputField>

              {!restaurantRequiresUserCEP ? (
                <DesableCepButton type="button" onClick={handleDisableCep}>
                  {disableCep ? 'Quero informar meu CEP' : 'Não sei meu CEP'}
                </DesableCepButton>
              ) : (
                <Hyperlink
                  fontSize={12}
                  marginTop={8}
                  lineHeight="inherit"
                  fontFamily="Poppins"
                  fontWeight="bold"
                  color="#ff1814"
                  href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                >
                  Não sei meu CEP
                </Hyperlink>
              )}

              <StreetInputField>
                <InputField>
                  <Input
                    type="text"
                    placeholder="Digite sua rua ..."
                    name="street"
                    id="street"
                    autoComplete="off"
                  />
                </InputField>

                <InputField>
                  <Input
                    type="text"
                    placeholder="Número ..."
                    name="number"
                    autoComplete="off"
                  />
                </InputField>
              </StreetInputField>

              <InputField>
                <Input
                  type="text"
                  placeholder="Bairro ..."
                  name="neighboorhood"
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <Input
                  type="text"
                  placeholder="Cidade ..."
                  name="city"
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <Input
                  type="text"
                  name="complement"
                  placeholder="Complemento ..."
                  autoComplete="off"
                />
              </InputField>

              <InputField>
                <Input
                  type="text"
                  name="reference"
                  placeholder="Referência ..."
                />
              </InputField>

              <ButtonOrders type="submit">Cadastrar endereço</ButtonOrders>
            </Form>
          </AddressField>
        </Content>
      )}
    </Container>
  );
};

export default SearchAddressByDistance;
