import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  FormCardBody,
  FormCardContainer,
  FormCardFooter,
  FormCardHeader,
  FormCardInfo,
  FormCardMain,
  ShadowElement,
} from './styles';
import { ClubFormContext } from '../../../context/clubForm';

const FormCard = ({ children }) => {
  return (
    <FormCardContainer>
      <FormCardMain>{children}</FormCardMain>
    </FormCardContainer>
  );
};

FormCard.Header = ({ children }) => {
  return <FormCardHeader>{children}</FormCardHeader>;
};

const FormBody = ({ children }) => {
  const { getFormValue } = useContext(ClubFormContext);
  const bodyRef = useRef(null);

  const heightDiff = bodyRef.current
    ? bodyRef.current.scrollHeight - bodyRef.current.clientHeight
    : 0;

  const [scrollDiff, setScrollDiff] = useState(heightDiff);

  useEffect(() => {
    const { scrollTop, scrollHeight, clientHeight } = bodyRef.current;
    setScrollDiff(scrollHeight - clientHeight - scrollTop);
  }, [getFormValue('cep')]);

  return (
    <FormCardBody
      ref={bodyRef}
      onScroll={e => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        setScrollDiff(scrollHeight - clientHeight - scrollTop);
      }}
    >
      {children}
      <ShadowElement show={scrollDiff > 40} />
    </FormCardBody>
  );
};

FormCard.Body = FormBody;

FormCard.Info = ({ children }) => {
  return <FormCardInfo>{children}</FormCardInfo>;
};

FormCard.Buttons = ({ children, vertical }) => {
  return <FormCardFooter vertical={vertical}>{children}</FormCardFooter>;
};

export default FormCard;
