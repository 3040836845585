import React, { useState } from 'react';
import Modal from 'react-modal';
import { FiTrash2, FiInfo, FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import {
  Container,
  MenuItemBody,
  MenuItemImage,
  MenuItemDescription,
  MenuItemComplements,
  MenuItemQuantity,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  CancelButton,
} from './styles';

import { useCart } from '../../../context/cart';

const MenuItemList = ({ item }) => {
  const { removeFromCart, decrement, increment } = useCart();
  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleRemoveFromCart(id) {
    removeFromCart(id);

    closeModal();
  }

  function replaceValue(value) {
    const newValue = value.replace('.', ',');

    return newValue;
  }

  return (
    <Container>
      <MenuItemBody key={item.basket_id}>
        {item.image && (
          <MenuItemImage>
            <img
              src={item.image.url_thumb || item.image.url}
              alt={item.name}
              style={{ width: 64 }}
            />
          </MenuItemImage>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p>Deseja realmente excluir esse produto?</p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton
              onClick={() => {
                handleRemoveFromCart(item.basket_id);
              }}
            >
              Confirmar
            </ConfirmButton>
            <CancelButton onClick={closeModal}>Cancelar</CancelButton>
          </ModalFooter>
        </Modal>
        <MenuItemDescription>
          <strong>{item.name}</strong>

          {item.observation && (
            <div>
              <FiInfo color="var(--primary)" />

              <span>{item.observation}</span>
            </div>
          )}
          <MenuItemComplements>
            {item.complement_categories.map(category =>
              category.complements
                .filter(comp => comp.amount > 0)
                .map(items => <p key={items.name}>{items.name}</p>),
            )}
          </MenuItemComplements>
        </MenuItemDescription>
        <MenuItemQuantity>
          {item.use_weight ? (
            <strong> {`${replaceValue(item.weight)} kg`} </strong>
          ) : (
            <>
              {!item.is_unitary && (
                <FiMinusCircle
                  color="var(--primary)"
                  size={18}
                  onClick={() => {
                    decrement(item.id);
                  }}
                />
              )}

              <strong> {`${item.amount}`} </strong>

              {!item.is_unitary && (
                <FiPlusCircle
                  color="var(--primary)"
                  size={18}
                  onClick={() => {
                    increment(item.id);
                  }}
                />
              )}
            </>
          )}

          <FiTrash2 color="var(--primary)" size={18} onClick={openModal} />
        </MenuItemQuantity>
      </MenuItemBody>
    </Container>
  );
};

export default MenuItemList;
