import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background-color: #d4d4d4;
`;

export const ProgressBarFill = styled.div`
  width: ${({ value }) => (value ? `${value}%` : '0%')};
  height: 100%;
  background-color: #2ec9b7;
  border-radius: 6px;
  transition: width 0.3s;
`;
