import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f7;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #303030;
`;

export const Content = styled.div`
  margin: 0 auto;
  background: #f8f8f7;
  max-width: 1000px;
  height: 70vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  margin-bottom: 200px;

  a {
    text-decoration: none;
    color: #0c78f3;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const Footer = styled.footer`
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  position: fixed;
  bottom: 0;
`;

export const PoweredBy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    width: 80px;
    margin-left: 8px;
  }
`;

export const RestaurantLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  overflow: hidden;

  max-width: 150px;
  height: 150px;
  border-radius: 50%;

  img {
    width: 100%;
  }
`;

export const RestaurantName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Poppins';

  h1 {
    font-weight: bold;
    text-align: center;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  padding-bottom: 150px;
  margin-top: 40px;

  p {
    font-weight: bold;
    text-align: center;
  }
`;

export const MessageOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-top: 10px;

  p {
    font-weight: bold;
    text-align: center;
  }
`;

export const Loading = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 180px;
  }

  h1 {
    font-size: 20px;
    margin-top: 16px;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;
