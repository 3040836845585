import styled from 'styled-components';
import { ReactComponent as LogoImg } from '../../assets/images/iconTake.svg';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  padding: 10px;
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 24px;
    font-weight: 700;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  button {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }
`;

export const Logo = styled(LogoImg)`
  .cls-1 {
    fill: var(--primary);
  }
  width: 100px;
  margin-top: 40px;
`;
