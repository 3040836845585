import React, { useState, useEffect } from 'react';

import QrReader from 'react-qr-reader';
import { useOrder } from '../../context/order';

import { Container, StyledLink } from './styles';

const CodeLogin = props => {
  const [qrcode, setQrcode] = useState();

  const { closeOrder } = useOrder();

  useEffect(() => {
    closeOrder();
  }, [closeOrder]);

  function handleScan(data) {
    if (data) {
      setQrcode(data);
    }
  }

  function handleError(err) {
    console.error(err);
    alert('Não foi encontrado dispositivo de câmera');
  }

  function handleNavigate() {
    window.location.href = `${qrcode}`;
  }

  return (
    <Container>
      <h1>
        Leia o QR Code que está na mesa, para preencher o codigo da mesa
        automaticamente. <br />
      </h1>

      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: 260 }}
      />

      <input placeholder="Código da Mesa" value={qrcode} readOnly />

      <StyledLink onClick={handleNavigate}>Acessar cardápio</StyledLink>
    </Container>
  );
};

export default CodeLogin;
