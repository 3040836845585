import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'react-modal';
import { useAuth } from '../../../context/auth';
import { useCart } from '../../../context/cart';

import api from '../../../services/api';

import {
  Container,
  Content,
  Message,
  PaymentLink,
  Button,
  ConfirmPixButton,
  PixArea,
  ModalBody,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
  HelpPixButton,
} from './styles';

import logoPicpay from '../../../assets/images/picpay-logo_BR.png';

import faltaPouco from '../../../assets/images/faltaPouco.svg';
import pix from '../../../assets/images/pix.png';
import logopix from '../../../assets/images/logopix.png';
import { useBaskets } from '../../../context/BasketsContext';

const PrepaidAlmostThere = () => {
  const {
    picpayReferenceId,
    userPaymentMethod,
    isSmsServiceOptional,
    userChosePaySms,
    restaurantHasSmsService,
    userDeliveryType,
    pixCodePayment,
    zoopPixCodePayment,
    restaurantId,
  } = useAuth();

  const [buttonText, setButtonText] = useState('Já realizei o pagamento');
  const [buttonCopyText, setButtonCopyText] = useState('Copiar Código Pix');
  const [modalVerifyPix, setModalVerifyPix] = useState(false);

  const { closeCart } = useCart();
  const [modalIsOpen, setIsOpen] = useState(
    userPaymentMethod.name.toString() === 'pix',
  );

  const history = useHistory();

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function toggleModalVerifyPix() {
    setModalVerifyPix(!modalVerifyPix);
  }

  async function handleNavigate() {
    if (userPaymentMethod.name.toString() === 'pix') {
      try {
        const response = await api.post('client/verify-pix', {
          zoop_id: zoopPixCodePayment,
        });

        if (response.data.paid) {
          closeCart();
          history.push(`/menu/${restaurantId}`);
        } else {
          toggleModalVerifyPix();
        }
      } catch (e) {
        alert('Erro rota validar pix');
      }
    } else {
      closeCart();

      history.push(`/menu/${restaurantId}`);
    }
  }

  // console.log('userPaymentMethod name', userPaymentMethod.name.toString());
  // console.log('userPaymentMethod type', userPaymentMethod.type.toString());

  // console.log('userDeliveryType', userDeliveryType.toString());

  // console.log('restaurantHasSmsService', restaurantHasSmsService.toString());
  // console.log('isSmsServiceOptional', isSmsServiceOptional.toString());
  // console.log('userChosePaySms', userChosePaySms.toString());

  return (
    <Container>
      <Content>
        <Message>
          {userPaymentMethod.name.toString() !== 'pix' ? (
            <>
              <img
                src={faltaPouco}
                alt="Ilustração com pessoa segurando o celular"
              />
              <h2>
                {userPaymentMethod.name.toString() === 'credito'
                  ? 'Pagamento confirmado!'
                  : 'Agora falta pouco'}
              </h2>
            </>
          ) : (
            <>
              <img src={logopix} alt="Pix" style={{ width: 150 }} />
              <h3>Finalize o pagamento via Pix!</h3>
            </>
          )}

          {(() => {
            if (restaurantHasSmsService.toString() === 'true') {
              if (
                (isSmsServiceOptional.toString() === 'true' &&
                  userChosePaySms.toString() === 'true') ||
                (restaurantHasSmsService.toString() === 'true' &&
                  isSmsServiceOptional.toString() === 'false')
              ) {
                return (
                  <>
                    {userPaymentMethod.name() === 'pix' && (
                      <PixArea>
                        <p>Código Pix Copia e Cola</p>
                        <input value={pixCodePayment} />
                        <ConfirmPixButton
                          onClick={() => {
                            navigator.clipboard.writeText(pixCodePayment);
                            setButtonCopyText('Código Copiado!');
                          }}
                        >
                          {buttonCopyText}
                        </ConfirmPixButton>
                        <HelpPixButton
                          style={{ marginTop: 5 }}
                          onClick={() => {
                            setIsOpen(true);
                          }}
                        >
                          Como pagar com Pix
                        </HelpPixButton>
                        {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                        <p>
                          <strong>O Código expira em 5 minutos!</strong>
                        </p>
                      </PixArea>
                    )}
                  </>
                );
              }
              return (
                <>
                  {userPaymentMethod.name.toString() === 'credito' &&
                    !userDeliveryType && (
                      <p>
                        Seu pedido já foi enviado para o restaurante.
                        Agradecemos pela compra!
                      </p>
                    )}

                  {userPaymentMethod.name.toString() === 'pix' && (
                    <PixArea>
                      <p>Código Pix Copia e Cola</p>
                      <input value={pixCodePayment} />
                      <ConfirmPixButton
                        onClick={() => {
                          navigator.clipboard.writeText(pixCodePayment);
                          setButtonCopyText('Código Copiado!');
                        }}
                      >
                        {buttonCopyText}
                      </ConfirmPixButton>
                      <HelpPixButton
                        style={{ marginTop: 5 }}
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        Como pagar com Pix
                      </HelpPixButton>
                      {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                      <p>
                        <strong>O Código expira em 5 minutos!</strong>
                      </p>
                    </PixArea>
                  )}
                </>
              );
            }
            return (
              <>
                {userPaymentMethod.name.toString() === 'pix' && (
                  <PixArea>
                    <p>Código Pix Copia e Cola</p>
                    <input value={pixCodePayment} />
                    <ConfirmPixButton
                      onClick={() => {
                        navigator.clipboard.writeText(pixCodePayment);
                        setButtonCopyText('Código Copiado!');
                      }}
                    >
                      {buttonCopyText}
                    </ConfirmPixButton>
                    <HelpPixButton
                      style={{ marginTop: 5 }}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      Como pagar com Pix
                    </HelpPixButton>
                    {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                    <p>
                      <strong>O Código expira em 5 minutos!</strong>
                    </p>
                  </PixArea>
                )}
                {userPaymentMethod.name.toString() === 'credito' && (
                  <p>
                    Seu pedido já foi enviado para o restaurante. Agradecemos
                    pela compra!
                  </p>
                )}

                {/* {userPaymentMethod.type.toString() === 'online' &&
                  userPaymentMethod.name.toString() === 'credito' &&
                  userDeliveryType.toString() === 'delivery' && (
                    <p>
                      Seu pedido já foi enviado para o restaurante, acompanhe o status na página
                        <strong> Meus Pedidos</strong>.
                    </p>
                  )} */}
              </>
            );
          })()}
        </Message>

        {userPaymentMethod.name.toString() === 'credito' ? (
          <Button onClick={handleNavigate}>Ok, entendi !</Button>
        ) : (
          <Button onClick={handleNavigate}>Já realizei o pagamento</Button>
        )}
      </Content>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Como pagar pedidos com Pix</p>
        </ModalTitle>
        <ModalBody>
          <div>
            <img src={pix} alt="logopix" />
          </div>
          <p>
            <strong>1º Passo</strong>
          </p>
          <p>Copie o código que foi gerado</p>
          <p style={{ marginTop: 13 }}>
            <strong>2º Passo</strong>
          </p>
          <p>
            Abra um aplicativo em que você tenha o Pix habilitado e use a opção
            Pix Copia e Cola
          </p>
          <p style={{ marginTop: 13 }}>
            <strong>3º Passo</strong>
          </p>
          <p>
            Cole o código, confirme o valor e faça o pagamento. Ele será
            confirmado em no máximo 2 minutos!
          </p>
        </ModalBody>
        <ModalFooter>
          <ConfirmButton onClick={closeModal}>Ok, entendi!</ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalVerifyPix}
        onRequestClose={toggleModalVerifyPix}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Pagamento sendo processado...</p>
        </ModalTitle>

        <ModalFooter>
          <ConfirmButton onClick={toggleModalVerifyPix}>
            Ok, entendi!
          </ConfirmButton>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default PrepaidAlmostThere;
