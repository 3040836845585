import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Rating from 'react-rating';
import { FaStar } from 'react-icons/fa';
import { LoadingPage } from 'ui-kit-takeat';
import Avaliar from '../../assets/images/avaliar.svg';

import api from '../../services/api';

import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  Message,
  Observations,
  ButtonRating,
  Footer,
} from './styles';

const RestaurantRating = () => {
  const history = useHistory();
  const { sessionKey } = useAuth();
  const [selectedItem, setSelectedItem] = useState([]);
  const [clientRating, setClientRating] = useState(0);
  const [comment, setComment] = useState('');
  const [sendingRating, setSandingRating] = useState(false);

  function handleSetComment(e) {
    setComment(e);
  }

  async function handleSubmit() {
    try {
      setSandingRating(true);
      await api.post('/client/ratings', {
        stars: clientRating,
        ratings: selectedItem,
        session_key: sessionKey,
        comment,
      });

      setSandingRating(false);

      history.push('/');
    } catch (error) {
      setSandingRating(false);
      console.log(error);
      history.push('/');
    }
  }

  function handleSelectRating(rating) {
    const findItem = selectedItem.findIndex(item => item === rating);

    if (findItem >= 0) {
      const filteredItems = selectedItem.filter(item => item !== rating);

      setSelectedItem(filteredItems);
    } else {
      setSelectedItem([...selectedItem, rating]);
    }
  }

  function handleRate(rate) {
    setClientRating(rate);
  }

  const compliments = [
    'Tempero',
    'Atendimento',
    'Inovação',
    'Agilidade',
    'Limpeza',
  ];
  const criticism = ['Sujeira', 'Atendimento ruim', 'Sem tempero'];

  return sendingRating ? (
    <LoadingPage duration={3} text="Enviando Avaliação ..." />
  ) : (
    <Container>
      <Content>
        <Message>
          <img src={Avaliar} alt="Pessoa segurando estrela" />

          <h1>Obrigado por nos avaliar!</h1>

          <p>
            <Rating
              emptySymbol={<FaStar size={28} color="#33333330" />}
              fullSymbol={<FaStar size={28} color="orange" />}
              onChange={rate => {
                handleRate(rate);
              }}
              onClick={rate => {
                handleRate(rate);
              }}
              initialRating={clientRating}
            />
          </p>

          <textarea
            placeholder="Deixe seu comentário ..."
            maxLength={240}
            onChange={e => handleSetComment(e.target.value)}
          />
        </Message>

        {clientRating >= 3 && (
          <>
            <Observations>
              {compliments.map(compliment => (
                <li
                  key={compliment}
                  onClick={() => {
                    handleSelectRating(compliment);
                  }}
                  className={
                    selectedItem.includes(compliment) ? 'selected' : ''
                  }
                >
                  {compliment}
                </li>
              ))}
            </Observations>
            <Footer>
              <ButtonRating onClick={handleSubmit} className="btn-primary">
                {' '}
                Enviar{' '}
              </ButtonRating>
            </Footer>
          </>
        )}

        {(clientRating >= 1) & (clientRating < 3) ? (
          <>
            <Observations>
              {criticism.map(critic => (
                <li
                  key={critic}
                  onClick={() => {
                    handleSelectRating(critic);
                  }}
                  className={selectedItem.includes(critic) ? 'selected' : ''}
                >
                  {critic}
                </li>
              ))}
            </Observations>

            <Footer>
              <ButtonRating onClick={handleSubmit} className="btn-primary">
                {' '}
                Enviar{' '}
              </ButtonRating>
            </Footer>
          </>
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default RestaurantRating;
