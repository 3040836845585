import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 410px;
`;

export const MenuItemBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding-top: 20px;

  & + div {
    margin-top: 20px;
    border-top: 1px solid #33333320;
  }
`;

export const MenuItemImage = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    border-radius: 7px;
    margin-right: 15px;
  }
`;

export const MenuItemDescription = styled.div`
  width: 490px;

  > div {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      font-size: 12px;
    }

    svg {
      margin-right: 5px;
    }
  }

  p {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const MenuItemComplements = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    color: var(--primary);
    font-size: 10px;
    padding: 3px 5px;
    margin-right: 5px;
    border: 1px solid var(--primary);
    border-radius: 14px;
  }
`;

export const MenuItemQuantity = styled.div`
  display: flex;
  width: 150px;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-left: 10px;
    cursor: pointer;
  }

  strong {
    margin-left: 8px;
  }

  p {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--primary);
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #2ec9b7;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;
`;
