import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Title, ButtonGroup, Content, Logo } from './styles';

const Home = () => {
  return (
    <Container>
      <Content>
        <Title>
          Bem-vindo(a) à <br />
          <span>Takeat Sistemas</span>
          <Logo />
        </Title>

        <p>Escolha uma opção de atendimento</p>

        <ButtonGroup>
          <Link to="/app/auto">
            <button className="btn-primary">Garçom Digital</button>
          </Link>
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default Home;
