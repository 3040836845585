import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container, Content, Footer } from './styles';

import { useOrder } from '../../context/order';

import errorImage from '../../assets/images/ERROR.png';
import { useAuth } from '../../context/auth';
import { Button } from 'ui-kit-takeat';

const RestaurantNotFound = ({ error, errorMessage, errorAction }) => {
  const { setErrorComponent } = useOrder();
  const history = useHistory();
  const { restaurantName } = useAuth();

  function handleNavigate() {
    history.push('/');
  }
  return (
    <Container>
      <Content>
        <img src={errorImage} alt="ilustracao de erro ao fazer pedido" />

        <h1>Opa! Parece que houve algum erro ...</h1>
        <p>
          O restaurante que você está tentando acessar, não foi encontrado.
          Verifique se foi digitado algo errado.
        </p>
      </Content>
      <Footer>

        <Button
          title="Vou verificar"
          style={{ borderRadius: 40 }}
          textStyle={{ fontWeight: 700 }}
          onClick={handleNavigate}
        />
      </Footer>
    </Container>
  );
};

export default RestaurantNotFound;
