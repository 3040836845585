/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { Container } from './styles';
import MenuScrollItem from './MenuScrollItem';
import { useAuth } from '../../context/auth';

function MenuScrollNavFixed({
  menu,
  handleExpandCategory,
  tableType,
  menuTranslationsEnabled,
  menuLanguage,
  categoryShowing,
  positionType,
}) {
  const { isMenuVisible } = useAuth()
  //menu fixo

  return tableType === 'delivery' ? (
    <Container positionType={positionType} isVisible={isMenuVisible}>
      <ScrollContainer
        className="scroll-container"
        vertical={false}
        style={{ height: 'fit-content' }}
      >
        {menu
          .filter(cat => cat.available_in_delivery && !cat.is_exclusive)
          .map((category, idx) => {
            return (
              <React.Fragment key={category.id}>
                {category.products.length > 0 && (
                  <MenuScrollItem
                    category={category}
                    handleExpandCategory={handleExpandCategory}
                    menuTranslationsEnabled={menuTranslationsEnabled}
                    menuLanguage={menuLanguage}
                    isActive={categoryShowing === idx}
                  />
                )}
              </React.Fragment>
            );
          })}
      </ScrollContainer>
    </Container>
  ) : (
    <Container positionType={positionType} isVisible={isMenuVisible}>
      <ScrollContainer className="scroll-container">
        {menu
          .filter(cat => !cat.is_exclusive)
          .map((category, idx) => (
            <React.Fragment key={category.id}>
              {category.products.length > 0 && (
                <MenuScrollItem
                  category={category}
                  handleExpandCategory={handleExpandCategory}
                  menuTranslationsEnabled={menuTranslationsEnabled}
                  menuLanguage={menuLanguage}
                  isActive={categoryShowing === idx}
                />
              )}
            </React.Fragment>
          ))}
      </ScrollContainer>
    </Container>
  )
}

export default MenuScrollNavFixed;
