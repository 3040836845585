import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import api from '../../services/api';
import { useAuth } from '../auth';
import { toast } from 'react-toastify';

// import { Container } from './styles';

const BasketsContext = createContext();

function BasketsProvider({ children }) {
  const [baskets, setBaskets] = useState([]);
  const { restaurantId, userToken } = useAuth();
  const { tableType, websocketManager } = useAuth();
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [hasOrder, setHasOrder] = useState([]);

  const fetchData = useCallback(async clicked => {
    setLoadingUpdate(true);
    try {
      const response = await api.get('/client/delivery/sessions');

      const orders = response.data
        .map(item => {
          const rescues = item.bills.map(bills => bills.rescues[0])[0];

          return {
            ...item.bills.map(bills => bills.order_baskets[0])[0],
            rescues: rescues ? rescues : null,
            scheduled_to: item.scheduled_to,
            session_id: item.id,
            pix_payments: item.pix_payments || [],
            total_delivery_price: item.total_delivery_price,
            restaurant_id: item.restaurant_id,
          };
        })
        .filter(item => item);

      setBaskets(orders);

      setHasOrder(
        orders.filter(
          order =>
            order.order_status !== 'canceled' &&
            order.order_status !== 'finished' &&
            order.restaurant_id === restaurantId,
        ),
      );

      // const checkDone = orders
      //   .map(item => item[0][0])
      //   .filter(order => order.order_status === 'ready');
      // // const checkWaitingPayment = orders.filter(
      // //   order => order.order_status === 'waiting_payment',
      // // );
      // const checkAccepeted = orders
      //   .map(item => item[0][0])
      //   .filter(order => order.order_status === 'accepted');
      // const checkPending = orders
      //   .map(item => item[0][0])
      //   .filter(order => order.order_status === 'pending');

      // setBaskets(state => [
      //   ...state,
      //   ...checkDone,
      //   ...checkAccepeted,
      //   ...checkPending,
      // ]);

      // const checkCanceled = orders
      //   .map(item => item[0][0])
      //   .filter(order => order.order_status === 'canceled');
      // // const checkWaitingPayment = orders.filter(
      // //   order => order.order_status === 'waiting_payment',
      // // );
      // const checkFinished = orders
      //   .map(item => item[0][0])
      //   .filter(order => order.order_status === 'finished');

      // setBaskets(state => [...state, ...checkCanceled, ...checkFinished]);
      if (clicked) {
        toast.success('Pedidos atualizados');
      }
    } catch (err) {
      toast.error('Erro ao atualizar pedidos. Tente novamente em breve');
      console.log('fetchData error: ', err);
    }
    setLoadingUpdate(false);
  }, []);

  const value = useMemo(
    () => ({ baskets, fetchData, setBaskets, hasOrder, loadingUpdate }),
    [baskets, fetchData, setBaskets, hasOrder, loadingUpdate],
  );

  useEffect(() => {
    if (tableType === 'delivery' && userToken) {
      fetchData();
    }
  }, [fetchData, tableType, userToken]);

  return (
    <BasketsContext.Provider value={value}>{children}</BasketsContext.Provider>
  );
}

function useBaskets() {
  const context = useContext(BasketsContext);

  if (!context) {
    throw new Error('useTables must be used within an BasketProvider');
  }

  return context;
}

export { useBaskets, BasketsProvider };
