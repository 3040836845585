import React, { useState } from 'react';

import QrReader from 'react-qr-reader';

import { Container, StyledLink } from './styles';

const BitLogin = props => {
  const [qrcode, setQrcode] = useState();

  function handleScan(data) {
    if (data) {
      setQrcode(data);
    }
  }

  function handleError(err) {
    console.error(err);
    alert('Não foi encontrado dispositivo de câmera');
  }

  function handleNavigate() {
    window.location.href = `${qrcode}`;
  }

  return (
    <Container>
      <h1>
        Leia o QR Code que está na mesa, para preencher o codigo da mesa
        automaticamente. <br />
      </h1>

      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: 260 }}
      />

      <input placeholder="Código da Mesa" value={qrcode} readOnly />

      <StyledLink onClick={handleNavigate}>Acessar cardápio</StyledLink>
    </Container>
  );
};

export default BitLogin;
