export default function formatTimeInSaoPauloTimeZone(isoDateString) {
  const date = new Date(isoDateString);

  const saoPauloTimeZoneOffset = -3 * 60;
  const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
  const saoPauloTime = new Date(utcTime + (saoPauloTimeZoneOffset * 60000));

  const day = pad(saoPauloTime.getDate());
  const month = pad(saoPauloTime.getMonth() + 1);
  const year = saoPauloTime.getFullYear();
  const hours = pad(saoPauloTime.getHours());
  const minutes = pad(saoPauloTime.getMinutes());

  const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;

  return formattedDate;
}

// Função utilitária para adicionar zeros à esquerda se o número for menor que 10
function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}
