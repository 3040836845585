import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BackButtonContainer } from './styles';
import { MdArrowBack } from 'react-icons/md';

export const BackButton = ({ children, onClick }) => {
  const history = useHistory();

  return (
    <BackButtonContainer
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          history.goBack();
        }
      }}
    >
      <MdArrowBack />
      {children}
    </BackButtonContainer>
  );
};
