import React, { useContext, useEffect, useState } from 'react';
import FormCard from '../../FormCard';
import { CustomInput } from '../../CustomInput';
import { ClubFormContext } from '../../../../context/clubForm';
import { CustomSelect } from '../../CustomSelect';
import { Button } from 'ui-kit-takeat';
import { getCities, getStates } from '@brazilian-utils/brazilian-utils';

export const Address = () => {
  const {
    getFormValue,
    updateForm,
    formErrors,
    loadingAddress,
    submitClubForm,
    setFormStep,
    loadingSubmit,
    cepByAddress,
  } = useContext(ClubFormContext);

  const STATES = getStates().map(state => {
    return {
      label: state.name,
      value: state.code,
    };
  });
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setCities(
      getCities(getFormValue('state')).map(city => {
        return {
          value: city,
          label: city,
        };
      }),
    );
  }, [getFormValue('state')]);

  useEffect(() => {
    const firstItem = Object.keys(formErrors)[0];
    if (firstItem) {
      const element = document.getElementsByName(firstItem)[0];
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [formErrors]);

  return (
    <>
      <FormCard.Header>Endereço</FormCard.Header>
      <FormCard.Body>
        <CustomInput
          inputMode="numeric"
          name="cep"
          title="CEP*"
          placeholder="xx.xxx-xxx"
          mask="99.999-999"
          value={getFormValue('cep')}
          onChange={e => updateForm('cep', e.target.value)}
          error={formErrors.cep}
          isLoading={loadingAddress}
          disabled={loadingAddress}
        />

        <CustomSelect
          label="Estado*"
          name="state"
          placeholder="Selecione seu estado"
          controlStyles={`border-radius: 10px; height: 48px;`}
          options={STATES}
          value={
            STATES.find(option => option.value === getFormValue('state')) ||
            null
          }
          onChange={e => updateForm('state', e.value)}
          error={formErrors.state}
          isLoading={loadingAddress}
          disabled={loadingAddress}
        />
        <CustomSelect
          label="Cidade*"
          name="city"
          placeholder="Selecione sua cidade"
          controlStyles={`border-radius: 10px; height: 48px;`}
          options={cities}
          value={
            cities.find(option => option.value === getFormValue('city')) || null
          }
          onChange={e => updateForm('city', e.value)}
          error={formErrors.city}
          isLoading={loadingAddress}
          disabled={loadingAddress}
        />
        <div style={{ display: 'flex', gap: 8 }}>
          <CustomInput
            name="address"
            title="Logradouro*"
            placeholder="Digite seu logradouro"
            value={getFormValue('address')}
            onChange={e => updateForm('address', e.target.value)}
            error={formErrors.address}
            isLoading={loadingAddress}
            disabled={loadingAddress}
          />

          <Button
            title="Buscar CEP"
            onClick={() => cepByAddress()}
            textStyle={{ fontWeight: 700, color: '#2ec9b7' }}
            containerStyles={{
              borderRadius: 10,
              width: 155,
              marginTop: 21,
              padding: 10,
              height: 48,
            }}
            inverted
            buttonColor="#2ec9b7"
            isLoading={loadingAddress}
            disabled={loadingAddress}
          />
        </div>
      </FormCard.Body>
      <FormCard.Buttons>
        <Button
          title="Voltar"
          inverted
          buttonColor="#c8131b"
          onClick={() => setFormStep(state => state - 1)}
        />
        <Button
          title="Finalizar"
          onClick={() => submitClubForm()}
          buttonColor="#2ec9b7"
          containerStyles={{ flexGrow: 1 }}
          disabled={loadingSubmit}
          isLoading={loadingSubmit}
        />
      </FormCard.Buttons>
    </>
  );
};
