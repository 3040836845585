import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  z-index: 1000;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  padding: 10px;
  height: 100vh;
  display: flex;
  padding-bottom: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
  }

  h1 {
    margin-top: 16px;
    font-size: 20px;
    text-align: center;
  }

  p {
    text-align: center;
    margin-top: 8px;
    padding-right: 10px;
    padding-left: 10px;

    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  max-width: 410px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform:translateX(-50%);

  button {
    width: 100%;

  }
`;
