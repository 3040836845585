import styled from 'styled-components';

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
  padding: 8px 16px;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    margin: 0;
    z-index: 99;
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
  }

  p {
    margin: 0;
    z-index: 99;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    max-width: 100%;
    margin-bottom: 8px;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  button {
    z-index: 99;
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #ffa814;
    border: none;
    width: fit-content;
    max-width: 65%;

    animation: pulse 5s linear infinite;

    color: #fff;
    text-transform: uppercase;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
  }

  @keyframes pulse {
    0% {
      scale: 1;
    }
    10% {
      scale: 1.15;
    }
    20% {
      scale: 1;
    }
    30% {
      scale: 1.15;
    }
    40% {
      scale: 1;
    }
    50% {
      scale: 1;
    }
  }
`;
