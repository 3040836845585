import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  Message,
  Footer,
  ModalFooter,
  ModalTitle,
  ConfirmButton,
} from './styles';

import localizacao from '../../assets/images/localizacao.svg';

const Welcome = () => {
  const {
    restaurantId,
    restaurantName,
    restaurantAvatar,
    restaurantIsActive,
  } = useAuth();
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(true);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function closeModal() {
    setIsOpen(false);
  }

  function handleNavigate() {
    if (restaurantIsActive === 'false') {
      toast.error('Restaurante não está ativo.');
      return;
    }
    history.push(`/menu/${restaurantId}`);
  }

  return (
    <Container>
      <Content>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <img src={localizacao} alt="ilustracao de geolocalização" />
          <ModalTitle>
            <p>
              A Takeat precisa da sua localização para o funcionamento perfeito
              do sistema, pedimos que nos dê permissão quando for solicitado.
            </p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>
        <Message>
          <img src={restaurantAvatar} alt="restaurantAvatar" />
          <div>
            <h1>
              Seja Bem-Vindo(a) ao
              {restaurantName}
            </h1>
            <p>Aproveite nosso cardápio e tenha um excelente apetite.</p>
          </div>
        </Message>
        <Footer>
          <button
            type="button"
            className="btn-primary"
            onClick={handleNavigate}
          >
            Acessar Cardápio
          </button>
        </Footer>
      </Content>
    </Container>
  );
};

export default Welcome;
