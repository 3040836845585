import { motion } from 'framer-motion';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
  padding: 20px 20px 20px 20px;
  height: 100vh;
  max-width: 414px;
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;

  p {
    margin: 0;
  }

  .react-datepicker {
    border: none;

    top: -20px;
    left: 0;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__month {
    width: 122%;
    margin: 0;

    @media (max-height: 667px) {
      width: 116%;
    }

    @media (min-height: 740px) and (max-height: 743px) {
      width: 111%;
    }

    @media (min-height: 890px) {
      width: 129%;
    }
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    background: #d9d9d9;
    color: #979797;
    border-radius: 3px;
  }

  .react-datepicker__navigation {
    opacity: 2;
    width: 50px;
    height: 50px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #c8131b;
  }

  .react-datepicker__navigation--next {
    right: -65px;

    @media (max-height: 667px) {
      right: -46px;
    }

    @media (min-height: 740px) and (max-height: 743px) {
      right: -32px;
    }

    @media (min-height: 890px) {
      right: -80px;
    }
  }

  .react-datepicker__day-names {
    background-color: #ffffff;

    div {
      width: 41px;
      margin: 0;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 35px;
      text-align: center;
      color: #666666;
    }

    div:first-child,
    div:last-child {
      color: #979797;
    }
  }

  .react-datepicker__day {
    width: 41px;
    margin: 0;

    margin-top: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }

  .react-datepicker__day--selected {
    background-color: #c8131b;
    color: #ffffff;
    border-radius: 20px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #f0f0f0;
  }

  .react-datepicker__current-month {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    color: #c8131b;

    height: 44px;
  }

  .react-datepicker__header {
    background: #f4f4f4;
    border: none;
    border-radius: 0px;
    text-transform: capitalize;
    width: 122%;

    @media (max-height: 667px) {
      width: 116%;
    }

    @media (min-height: 740px) and (max-height: 743px) {
      width: 111%;
    }

    @media (min-height: 890px) {
      width: 129%;
    }
  }

  .react-datepicker__input-container {
    width: fit-content;
  }
  .react-datepicker-wrapper {
    width: fit-content;
  }
  input {
    background: transparent;
    border: none;
    width: 185px;
    text-transform: capitalize;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #666666;
  }
`;

export const HoursAvailable = styled.div`
  max-height: 270px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;

  @media (max-height: 667px) {
    max-height: 135px;
  }

  @media (min-height: 740px) and (max-height: 743px) {
    max-height: 198px;
  }

  @media (min-height: 890px) {
    max-height: 343px;
  }
`;

export const DayButton = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${props => (props.isSelected ? '500' : '400')};
  font-size: 16px;
  color: ${props => (props.isSelected ? '#ffffff' : '#4D4D4C')};

  height: 58px;
  width: 58px;
  border-radius: 5px;
  margin-right: 10px;
  background-color: ${props =>
    props.disabled ? '#D9D9D9' : props.isSelected ? '#FF1C32' : '#fff0f2'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
`;

export const DaysContainer = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 20px;
  overflow-x: scroll;

  &:-webkit-scrollbar {
    display: none;
  }
`;

export const HourCheckbox = styled(motion.div)`
  display: flex;
  width: fit-content;
  align-items: center;
  margin-bottom: 18px;

  div {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 5px;
    background: ${props =>
      props.disabled ? '#979797' : props.selected ? '#FF1C32' : '#ffffff'};
    border: 1px solid ${props => (props.selected ? '#FF1C32' : '#979797')};
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  color: #000000;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4d4d4c;
`;

export const ConfirmButton = styled.button`
  background: linear-gradient(90deg, #c8131b 2.09%, #ff2e15 97.43%);
  border-radius: 8px;
  width: 100%;
  max-width: 414px;
  height: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
`;

export const ArrowContainer = styled.div`
  width: 58px;
  height: 58px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  transition: all 0.2s;
  opacity: ${props => (props.show ? '1' : '0')};
  pointer-events: ${props => !props.show && 'none'};
`;
