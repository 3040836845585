import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const OrderValue = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  p {
    font-weight: bold;
    color: #333;
  }
`;
export const OrderTime = styled.p`
  font-size: 12px;
`;
export const Order = styled.div`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: #333;
  border-radius: 7px;
  padding: 8px;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);

  & + div {
    margin-top: 16px;
  }
`;
export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-weight: bold;
  }
`;

export const OrderBody = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const OrderFooter = styled(Link)`
  text-decoration: none;
  margin-top: 16px;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #33333310;

  p {
    font-size: 14px;
    color: #333;
  }
`;

export const OrderStatus = styled.div`
  margin-top: 16px;
  p {
    font-size: 14px;
    color: #ff0000;
  }
`;
