import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #333;
  color: #fff;
  height: 100vh;
  justify-content: space-around;
  align-items: center;
  padding: 10px;

  h1 {
    font-size: 16px;
    text-align: center;
  }

  form {
    width: 340px;
  }

  input {
    width: 340px;
    height: 48px;
    color: #fff;
    background: transparent;
    border-radius: 7px;
    border: 1px solid #fff;
    margin-bottom: 10px;
    text-align: center;

    &::placeholder {
      text-align: center;
    }
  }
`;

export const StyledLink = styled.button`
  width: 340px;
  height: 48px;
  background: var(--primary);
  color: #fff;
  border-radius: 7px;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
