import React from 'react';
import { BannerContainer } from './styles';

const CashbackBanner = ({ onClick, children, src, ...rest }) => {
  return (
    <BannerContainer
      {...rest}
      src={src}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <img src={src} />
      {children}
    </BannerContainer>
  );
};

export default CashbackBanner;
