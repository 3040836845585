import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { uuid } from 'uuidv4';

import { useOrder } from '../order/index';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const { addToOrder } = useOrder();

  const [cart, setCart] = useState(() => {
    const cartStorage = localStorage.getItem('@garcomdigital:cart');

    if (cartStorage) {
      return JSON.parse(cartStorage);
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem('@garcomdigital:cart', JSON.stringify(cart));
  }, [cart]);

  // cart-pixel
  const [cartPixel, setCartPixel] = useState(() => {
    const cartStoragePixel = localStorage.getItem('@garcomdigital:cartPixel');

    if (cartStoragePixel) {
      return JSON.parse(cartStoragePixel);
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem('@garcomdigital:cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:cartPixel', JSON.stringify(cartPixel));
  }, [cartPixel]);

  const addCartToOrder = useCallback(
    async (
      number,
      name,
      payment_method,
      table_type,
      command_table_number,
      closeCart,
      payment_token,
      rescue,
    ) => {
      await addToOrder(
        cart,
        number,
        name,
        payment_method,
        table_type,
        command_table_number,
        closeCart,
        payment_token,
        rescue,
      );
    },
    [cart, addToOrder],
  );

  const closeCart = useCallback(() => {
    localStorage.removeItem('@garcomdigital:cart');

    setCart([]);
  }, []);

  const closeCartPixel = useCallback(() => {
    localStorage.removeItem('@garcomdigital:cartPixel');

    setCartPixel([]);
  }, []);

  const increment = useCallback(
    id => {
      const newCart = [...cart];
      const findCart = newCart.findIndex(item => item.id === id);

      newCart[findCart].amount += 1;

      setCart(newCart);
      setCartPixel(newCart);
    },
    [cart],
  );

  const decrement = useCallback(
    id => {
      const newCart = [...cart];
      const findCart = newCart.findIndex(item => item.id === id);

      if (newCart[findCart].amount > 1) {
        newCart[findCart].amount -= 1;
      }

      setCart(newCart);
      setCartPixel(newCart);
    },
    [cart],
  );

  const addToCart = useCallback(
    basket => {
      setCart([
        ...cart,
        {
          basket_id: uuid(),
          id: basket.product.id,
          name: basket.product.name,
          price: basket.product.price,
          image: basket.product.image,
          amount: basket.amount,
          observation: basket.observation,
          complement_categories: basket.complementsCategory,
          use_weight: basket.isProductWeight || false,
          weight: basket.inputProductWeight || null,
          is_unitary: basket.product.is_unitary,
          is_combo: basket.product.is_combo,
        },
      ]);

      setCartPixel([
        ...cart,
        {
          basket_id: uuid(),
          id: basket.product.id,
          name: basket.product.name,
          price: basket.product.price,
          image: basket.product.image,
          amount: basket.amount,
          observation: basket.observation,
          complement_categories: basket.complementsCategory,
          use_weight: basket.isProductWeight || false,
          weight: basket.inputProductWeight || null,
          is_unitary: basket.product.is_unitary,
          is_combo: basket.product.is_combo,
        },
      ]);
    },
    [cart],
  );

  const removeFromCart = useCallback(
    id => {
      const newCart = [...cart];
      const productId = newCart.findIndex(item => item.basket_id === id);

      if (productId >= 0) {
        const cartFiltered = newCart.filter(
          product => product.basket_id !== id,
        );

        setCart(cartFiltered);
        setCartPixel(cartFiltered);
      } else {
        console.log('Produto nao existe');
      }
    },
    [cart],
  );

  const value = useMemo(
    () => ({
      addToCart,
      decrement,
      increment,
      cart,
      cartPixel,
      closeCartPixel,
      closeCart,
      addCartToOrder,
      removeFromCart,
    }),
    [
      addToCart,
      closeCart,
      decrement,
      increment,
      cart,
      cartPixel,
      closeCartPixel,
      addCartToOrder,
      removeFromCart,
    ],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    console.log('useCart must be within a provider');
  }

  return context;
};

export { CartProvider, useCart };
