import React from 'react';
import { SkeletonDiv } from './styles';

export const Skeleton = ({
  width = '100%',
  height = '20px',
  radius = '0px',
  ...props
}) => {
  return (
    <SkeletonDiv width={width} height={height} radius={radius} {...props} />
  );
};
