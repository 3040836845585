import React from 'react';
import { ProgressBarContainer, ProgressBarFill } from './styles';

export const ProgressBar = ({ value }) => {
  return (
    <ProgressBarContainer>
      <ProgressBarFill value={value} />
    </ProgressBarContainer>
  );
};
