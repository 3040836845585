import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { uuid } from 'uuidv4';
import Modal from 'react-modal';
import { ModalTitle, ModalFooter, ConfirmButton } from './styles';
import OrderError from '../../pages/OrderError';

import api from '../../services/api';

import { useAuth } from '../auth';
import { LoadingPage } from 'ui-kit-takeat';
import { toast } from 'react-toastify';

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const [errorComponent, setErrorComponent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorAction, setErrorAction] = useState([]);
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);
  const [modalErrorPhrase, setModalErrorPhrase] = useState();
  const [loadingPix, setLoadingPix] = useState(false);
  const [phraseToPix, setPhraseToPix] = useState(false);
  const history = useHistory();
  const {
    userLogin,
    tableLogin,
    sessionKey,
    setBillId,
    setBasketId,
    setSessionKey,
    userLocation,
    restaurantLocationLimited,
    restaurantId,
    restaurantName,
    logOut,
    userChosePaySms,
    userPaymentMethod,
    restaurantCouponCode,
    setRestaurantCouponCode,
    userChange,
    setPixCodePayment,
    setZoopPixCodePayment,
    userAddressId,
    userDeliveryType,
    isScheduling,
    scheduledTime,
    setUserPaymentMethod,
    tableType,
  } = useAuth();

  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
    overlay: {
      zIndex: 2,
    },
  };

  function toggleModal() {
    setIsModalErrorOpen(false);
  }

  const [orders, setOrders] = useState(() => {
    const orderStorage = localStorage.getItem('@garcomdigital:order');

    if (orderStorage) {
      return JSON.parse(orderStorage);
    }

    return [];
  });

  const [ordersTotalValue, setOrdersTotalValue] = useState(() => {
    const ordersTotalValueStorage = localStorage.getItem(
      '@garcomdigital:ordersTotalValue',
    );

    if (ordersTotalValueStorage) {
      return ordersTotalValueStorage;
    }

    return '';
  });

  const [productsNotAvailable, setProductsNotAvailable] = useState([]);
  const [isProductErrorModalOpened, setIsProductErrorModalOpened] = useState(
    false,
  );

  function openModalProductError(data) {
    setProductsNotAvailable(data);
    setIsProductErrorModalOpened(true);
  }

  function toggleModalProductError() {
    setIsProductErrorModalOpened(!isProductErrorModalOpened);
  }

  useEffect(() => {
    localStorage.setItem('@garcomdigital:ordersTotalValue', ordersTotalValue);
  }, [ordersTotalValue]);

  useEffect(() => {
    localStorage.setItem('@garcomdigital:order', JSON.stringify(orders));
  }, [orders]);

  async function SendErrorToServer(data) {
    await api.post('/public/client/log-error', {
      error: data,
    });
  }

  const addToOrder = useCallback(
    async (
      cart,
      number,
      name,
      payment_method,
      table_type,
      command_table_number,
      closeCart,
      payment_token,
      rescue = null,
    ) => {
      setOrders([...orders, { order: [...cart], id: uuid() }]);

      const lastOrder = [...cart];

      if (table_type === 'delivery') {
        const route = isScheduling ? 'scheduling' : 'delivery';
        switch (payment_method) {
          case 'delivery':
            try {
              setLoadingPix(true);
              await api.post(`/client/${route}/orders`, {
                order: lastOrder,
                will_receive_sms: userChosePaySms,
                payment_method_id: userPaymentMethod.id,
                with_withdrawal: false,
                user_change: userChange || 0,
                buyer_address_id: userAddressId,
                restaurant_id: restaurantId,
                coupon_code: restaurantCouponCode || '',
                scheduled_time: isScheduling && scheduledTime,
                rescue,
              });

              history.push('/app/almost-there');
              setRestaurantCouponCode('');
              closeCart();
              setLoadingPix(false);
            } catch (error) {
              setLoadingPix(false);
              if (!error.response.ok && error.response) {
                switch (error.response.data.errorType) {
                  case 'minimum_delivery_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor do pedido não é suficiente para delivery.',
                    );

                    history.push(`/${restaurantName}`);

                    break;

                  case 'coupon_minimum_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor da compra está abaixo do valor mínimo pro cupom',
                    );
                    history.push(`/${restaurantName}`);
                    setRestaurantCouponCode('');
                    break;

                  case 'service_unavailable':
                    history.push(`/app/systemDeliveryError`);
                    setRestaurantCouponCode('');

                    break;

                  case 'coupon_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Cupom não encontrado.');
                    setRestaurantCouponCode('');
                    break;

                  case 'products_not_available':
                    setLoadingPix(false);

                    openModalProductError(error.response.data.payload);

                    // setErrorComponent(true);
                    // setErrorMessage(
                    //   `Olá, não poderemos concluir o seu pedido pois o item a seguir não tem a quantidade suficiente disponível: ${productsNotAvailable.map(
                    //     item => (
                    //       <p>Item: ${item.product} - Disponível: ${parseInt(item.available)}</p>
                    //     ),
                    //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                    //       `,
                    // );
                    // setRestaurantCouponCode('');
                    setErrorAction([
                      () => {
                        history.push('/app/cart-delivery');
                      },
                    ]);
                    break;

                  case 'buyer_coupon_limit':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'coupon_amount_ended':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'restaurant_closed':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante fechado no momento.');
                    break;

                  case 'restaurant_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante não encontrado.');
                    break;

                  case 'restaurant_withdrawal_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Retirada não permitida pelo restaurante.',
                    );
                    break;

                  case 'restaurant_delivery_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Delivery não permitido pelo restaurante.',
                    );
                    break;

                  case 'club_rescue_error':
                    toast.error(error.response.data.message);
                    break;
                  case 'restaurant_not_active':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Restaurante não está ativo no momento.',
                    );
                    break;

                  default:
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Erro ao fazer o pedido, tente novamente ...',
                    );
                    setRestaurantCouponCode('');
                    break;
                }
              } else {
                setIsModalErrorOpen(true);
                setModalErrorPhrase(
                  'Erro ao fazer o pedido, tente novamente ...',
                );
                await api.post('/public/client/log-error', {
                  error: error.message,
                });
              }
            }
            break;
          case 'pix_online':
            try {
              setLoadingPix(true);
              setPhraseToPix(true);
              const response = await api.post(`/client/${route}/orders`, {
                order: lastOrder,
                will_receive_sms: userChosePaySms,
                payment_method_id: userPaymentMethod.id,
                with_withdrawal: userDeliveryType === 'withdrawal',
                user_change: userChange || 0,
                buyer_address_id: userAddressId || null,
                restaurant_id: restaurantId,
                coupon_code: restaurantCouponCode || '',
                scheduled_time: isScheduling && scheduledTime,
                rescue,
              });

              const { pix_info } = response.data;

              setPixCodePayment(pix_info.qrcode);
              setZoopPixCodePayment(pix_info.zoop_id);

              history.push('/app/almost-there');
              setRestaurantCouponCode('');
              setLoadingPix(false);
              setPhraseToPix(false);
              closeCart();
            } catch (error) {
              setLoadingPix(false);
              if (!error.response.ok && error.response) {
                switch (error.response.data.errorType) {
                  case 'minimum_delivery_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor do pedido não é suficiente para delivery.',
                    );
                    history.push(`/${restaurantName}`);
                    break;

                  case 'service_unavailable':
                    history.push(`/app/systemDeliveryError`);
                    setRestaurantCouponCode('');

                    break;

                  case 'coupon_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Cupom não encontrado.');
                    setRestaurantCouponCode('');
                    break;

                  case 'buyer_coupon_limit':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'products_not_available':
                    openModalProductError(error.response.data.payload);
                    setLoadingPix(false);
                    // setErrorComponent(true);
                    // setErrorMessage(
                    //   `Olá, não poderemos concluir o seu pedido pois o item a seguir não tem a quantidade suficiente disponível: ${productsNotAvailable.map(
                    //     item => (
                    //       <p>Item: ${item.product} - Disponível: ${parseInt(item.available)}</p>
                    //     ),
                    //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                    //       `,
                    // );
                    setRestaurantCouponCode('');
                    setErrorAction([
                      () => {
                        history.push('/app/cart-delivery');
                      },
                    ]);
                    break;

                  case 'coupon_amount_ended':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'restaurant_closed':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante fechado no momento.');
                    break;

                  case 'restaurant_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante não encontrado.');
                    break;

                  case 'restaurant_withdrawal_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O método Retirada de pedidos não está ativo no momento.',
                    );

                    break;

                  case 'restaurant_delivery_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Delivery não permitido pelo restaurante.',
                    );
                    break;

                  case 'coupon_minimum_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor da compra está abaixo do valor mínimo pro cupom',
                    );
                    history.push(`/${restaurantName}`);
                    setRestaurantCouponCode('');
                    break;

                  default:
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Erro ao fazer o pedido, tente novamente ...',
                    );
                    setRestaurantCouponCode('');
                    break;
                }
              } else {
                setIsModalErrorOpen(true);
                setModalErrorPhrase(
                  'Erro ao fazer o pedido, tente novamente ...',
                );
                await api.post('/public/client/log-error', {
                  error: error.message,
                });
              }
            }
            break;
          case 'credito':
            try {
              const response = await api.post(`/client/${route}/orders`, {
                order: lastOrder,
                will_receive_sms: userChosePaySms,
                payment_method_id: userPaymentMethod?.id,
                with_withdrawal: userDeliveryType === 'withdrawal',
                user_change: userChange || 0,
                buyer_address_id: userAddressId || null,
                restaurant_id: restaurantId,
                coupon_code: restaurantCouponCode || '',
                scheduled_time: isScheduling && scheduledTime,
                payment_token,
                payment_method,
                rescue,
              });

              history.push('/app/almost-there');
              setRestaurantCouponCode('');
              closeCart();
            } catch (error) {
              if (!error.response.ok && error.response) {
                switch (error.response.data.errorType) {
                  case 'minimum_delivery_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor do pedido não é suficiente para delivery.',
                    );
                    history.push(`/${restaurantName}`);
                    break;

                  case 'service_unavailable':
                    history.push(`/app/systemDeliveryError`);
                    setRestaurantCouponCode('');

                  case 'products_not_available':
                    openModalProductError(error.response.data.payload);
                    setLoadingPix(false);
                    // setErrorComponent(true);
                    // setErrorMessage(
                    //   `Olá, não poderemos concluir o seu pedido pois o item a seguir não tem a quantidade suficiente disponível: ${productsNotAvailable.map(
                    //     item => (
                    //       <p>Item: ${item.product} - Disponível: ${parseInt(item.available)}</p>
                    //     ),
                    //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                    //       `,
                    // );
                    setRestaurantCouponCode('');
                    setErrorAction([
                      () => {
                        history.push('/app/cart-delivery');
                      },
                    ]);
                    break;

                  case 'coupon_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Cupom não encontrado.');
                    setRestaurantCouponCode('');
                    break;

                  case 'buyer_coupon_limit':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'coupon_amount_ended':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'restaurant_closed':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante fechado no momento.');
                    break;

                  case 'restaurant_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante não encontrado.');
                    break;

                  case 'restaurant_withdrawal_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Retirada não permitida pelo restaurante.',
                    );
                    break;

                  case 'restaurant_delivery_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Delivery não permitido pelo restaurante.',
                    );
                    break;

                  case 'coupon_minimum_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor da compra está abaixo do valor mínimo pro cupom',
                    );
                    history.push(`/menu/${restaurantId}`);
                    setRestaurantCouponCode('');
                    break;

                  case 'paytime_api_request_failed':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Erro ao se comunicar com a operadora do cartão. Reveja os dados digitados ou entre em contato com o seu banco.',
                    );
                    break;

                  default:
                    const message = error.response.data.message
                      ? error.response.data.message
                      : 'Erro ao fazer o pedido, tente novamente ...';
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(message);
                    setRestaurantCouponCode('');
                    break;
                }
              } else {
                setIsModalErrorOpen(true);
                setModalErrorPhrase(
                  'Erro ao fazer o pedido, tente novamente ...',
                );
                await api.post('/public/client/log-error', {
                  error: error.message,
                });
              }
            }
            break;
          case 'withdrawal':
            try {
              setLoadingPix(true);
              await api.post(`/client/${route}/orders`, {
                order: lastOrder,
                will_receive_sms: userChosePaySms,
                payment_method_id: userPaymentMethod.id,
                with_withdrawal: true,
                user_change: userChange || 0,
                restaurant_id: restaurantId,
                coupon_code: restaurantCouponCode || '',
                scheduled_time: isScheduling && scheduledTime,
                rescue,
              });

              history.push('/app/almost-there');
              setRestaurantCouponCode('');
              closeCart();
              setLoadingPix(false);
            } catch (error) {
              setLoadingPix(false);
              if (!error.response.ok && error.response) {
                switch (error.response.data.errorType) {
                  case 'minimum_delivery_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor do pedido não é suficiente para delivery. Vamos te redirecionar para o menu',
                    );
                    history.push(`/${restaurantName}`);
                    break;

                  case 'service_unavailable':
                    history.push(`/app/systemDeliveryError`);
                    setRestaurantCouponCode('');

                    break;

                  case 'coupon_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Cupom não encontrado.');
                    setRestaurantCouponCode('');
                    break;

                  case 'restaurant_withdrawal_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O método Retirada de pedidos não está ativo no momento.',
                    );

                    setRestaurantCouponCode('');

                    break;

                  case 'products_not_available':
                    openModalProductError(error.response.data.payload);
                    setLoadingPix(false);
                    // setErrorComponent(true);
                    // setErrorMessage(
                    //   `Olá, não poderemos concluir o seu pedido pois o item a seguir não tem a quantidade suficiente disponível: ${productsNotAvailable.map(
                    //     item => (
                    //       <p>Item: ${item.product} - Disponível: ${parseInt(item.available)}</p>
                    //     ),
                    //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                    //       `,
                    // );
                    setRestaurantCouponCode('');
                    setErrorAction([
                      () => {
                        history.push('/app/cart-delivery');
                      },
                    ]);
                    break;

                  case 'buyer_coupon_limit':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'coupon_amount_ended':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Este cupom atingiu o limite de uso.');

                    setRestaurantCouponCode('');

                    break;

                  case 'restaurant_closed':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante fechado no momento.');
                    break;

                  case 'restaurant_not_found':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase('Restaurante não encontrado.');
                    break;

                  case 'restaurant_withdrawal_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Retirada não permitida pelo restaurante.',
                    );
                    break;

                  case 'restaurant_delivery_not_enabled':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Delivery não permitido pelo restaurante.',
                    );
                    break;

                  case 'coupon_minimum_price':
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'O valor da compra está abaixo do valor mínimo pro cupom',
                    );
                    setRestaurantCouponCode('');
                    history.push(`/${restaurantName}`);
                    break;

                  default:
                    setIsModalErrorOpen(true);
                    setModalErrorPhrase(
                      'Erro ao fazer o pedido, tente novamente ..',
                    );
                    setRestaurantCouponCode('');
                    break;
                }
              } else {
                setIsModalErrorOpen(true);
                setModalErrorPhrase(
                  'Erro ao fazer o pedido, tente novamente ...',
                );

                await api.post('/public/client/log-error', {
                  error: error.message,
                });
              }
            }
            break;
        }
      } else {
        const response = await userLogin(number, name);

        console.log(response)

        if (response !== undefined && response.toString() === "false") {
          setErrorComponent(true);
          setErrorMessage(
            "Essa comanda já está sendo utilizada por outro cliente, favor reportar ao restaurante."
          );
          setErrorAction([
            () => {
              history.push('/');
            },
          ]);
          return false
        }


        if (!sessionKey || tableType === 'prepaid') {
          tableLogin(
            lastOrder,
            command_table_number,
            closeCart,
            payment_method,
            payment_token,
          );
        } else if (restaurantLocationLimited.toString() === 'true') {
          try {
            if (payment_method === 'pix') {
              setLoadingPix(true);
              setPhraseToPix(true);
            }
            const res = await api.post('client/orders', {
              restaurant_id: restaurantId,
              order: lastOrder,
              session_key: payment_method ? null : sessionKey,
              command_table_number,
              client_position: {
                latitude: userLocation.latitude,
                longitude: userLocation.longitude,
              },
              payment_method: payment_method || null,
              payment_token:
                payment_method === 'credito' ? payment_token : null,
            });

            const { updatedBasket, updatedBill, tableSession } = res.data;

            if (payment_method === 'pix') {
              const { pix_info } = res.data;

              setPixCodePayment(pix_info.qrcode);
              setZoopPixCodePayment(pix_info.zoop_id);

              setUserPaymentMethod({ name: 'pix' });
              setLoadingPix(false);
              setPhraseToPix(false);
              history.push('/app/prepaid/almost-there');
            }

            if (payment_method === 'credito') {
              setUserPaymentMethod({ name: 'credito' });

              history.push('/app/prepaid/almost-there');
            }

            setBasketId(updatedBasket.basket_id);
            setBillId(updatedBill.id);
            closeCart();

            if (!payment_method) {
              history.push('/app/confirmOrder');
            }
          } catch (err) {
            if (!err.response?.ok && err.response) {
              switch (err.response.data.errorType) {
                case 'restaurant_closed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Restaurante fechado, tente novamente mais tarde, por favor!',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                case 'lounge_price_limit':
                  setErrorComponent(true);
                  setErrorMessage(err.response.data.message);
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                case 'service_unavailable':
                  history.push(`/app/systemError`);

                  break;

                case 'table_session_closing':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esta mesa está em fechamento, favor solicitar a liberação ao garçom.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                case 'wrong_restaurant_id':
                  logOut();
                  setErrorComponent(true);
                  setErrorMessage(
                    'Houve um erro inesperado no celular, mas não se preocupe, basta dar ok, e refazer o pedido.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/app/auto`);
                    },
                  ]);
                  break;

                case 'user_with_open_session':
                  const { redirect_url } = err.response.data.payload;

                  setErrorComponent(true);
                  setErrorMessage(
                    'Usuário já logado em outra mesa, estamos te redirecionando para sua mesa anterior',
                  );

                  setErrorAction([
                    () => {
                      window.location.href = redirect_url;
                    },
                  ]);
                  break;

                case 'products_not_available':
                  openModalProductError(err.response.data.payload);
                  setLoadingPix(false);
                  // setErrorComponent(true);
                  // setErrorMessage(
                  //   `Olá, não poderemos concluir o seu pedido pois o item a seguir não tem a quantidade suficiente disponível: ${productsNotAvailable.map(
                  //     item => (
                  //       <p>Item: ${item.product} - Disponível: ${parseInt(item.available)}</p>
                  //     ),
                  //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                  //         `,
                  // );

                  setErrorAction([
                    () => {
                      window.location.reload();
                    },
                  ]);
                  break;

                case 'far_from_restaurant':
                  history.push(`/app/farlocationerror`);
                  break;

                case 'no_geolocation_sent':
                  history.push(`/app/locationError`);
                  break;

                case 'wrong_product':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse produto não existe neste restaurante, favor verificar no cardápio.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                case 'paytime_api_request_failed':
                  setIsModalErrorOpen(true);
                  setModalErrorPhrase(
                    'Erro ao se comunicar com a operadora do cartão. Reveja os dados digitados ou entre em contato com o seu banco.',
                  );
                  break;

                case 'table_session_not_found':
                  localStorage.removeItem('@garcomdigital:sessionKey');
                  setSessionKey('');
                  tableLogin(lastOrder, command_table_number, closeCart);

                  break;

                default:
                  setErrorComponent(true);
                  setErrorMessage(
                    `Ocorreu um erro ao efetuar o pedido. Favor tentar novamente, ou mostar o erro ao gerente. : ${err.message}`,
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  closeCart();
                  break;
              }
            } else {
              await api.post('/public/client/log-error', {
                error: err.message,
              });
            }
          }
        } else {
          try {
            if (payment_method === 'pix') {
              setUserPaymentMethod({ name: 'pix' });
              setLoadingPix(true);
              setPhraseToPix(true);
            }
            const res = await api.post('client/orders', {
              restaurant_id: restaurantId,
              order: lastOrder,
              session_key: payment_method ? null : sessionKey,
              command_table_number,
              client_position: {
                latitude: 0,
                longitude: 0,
              },
              payment_method: payment_method || null,
              payment_token:
                payment_method === 'credito' ? payment_token : null,
            });

            const { updatedBasket, updatedBill, tableSession } = res.data;

            if (payment_method === 'pix') {
              const { pix_info } = res.data;

              setPixCodePayment(pix_info.qrcode);
              setZoopPixCodePayment(pix_info.zoop_id);

              history.push('/app/prepaid/almost-there');

              setLoadingPix(false);
              setPhraseToPix(false);
            }

            if (payment_method === 'credito') {
              setUserPaymentMethod({ name: 'credito' });
              history.push('/app/prepaid/almost-there');
            }

            setBasketId(updatedBasket.basket_id);
            setBillId(updatedBill.id);

            if (!payment_method) {
              history.push('/app/confirmorder');
            }
            closeCart();
          } catch (err) {
            if (!err.response?.ok && err.response) {
              switch (err.response.data.errorType) {
                case 'restaurant_closed':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Restaurante fechado, tente novamente mais tarde, por favor!',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                case 'service_unavailable':
                  history.push(`/app/systemError`);
                  setRestaurantCouponCode('');

                  break;

                case 'table_session_closing':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esta mesa está em fechamento, favor solicitar a liberação ao garçom.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                case 'paytime_api_request_failed':
                  setIsModalErrorOpen(true);
                  setModalErrorPhrase(
                    'Erro ao se comunicar com a operadora do cartão. Reveja os dados digitados ou entre em contato com o seu banco.',
                  );
                  break;

                case 'wrong_restaurant_id':
                  logOut();
                  setErrorComponent(true);
                  setErrorMessage(
                    'Houve um erro inesperado no celular, mas não se preocupe, basta dar ok, e refazer o pedido.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/app/auto`);
                    },
                  ]);
                  break;

                case 'user_with_open_session':
                  const { redirect_url } = err.response.data.payload;

                  setErrorComponent(true);
                  setErrorMessage(
                    'Usuário já logado em outra mesa, estamos te redirecionando para sua mesa anterior',
                  );

                  setErrorAction([
                    () => {
                      window.location.href = redirect_url;
                    },
                  ]);
                  break;

                case 'products_not_available':
                  openModalProductError(err.response.data.payload);
                  setLoadingPix(false);
                  // setErrorComponent(true);
                  // setErrorMessage(
                  //   `Olá, não poderemos concluir o seu pedido pois o item a seguir não tem a quantidade suficiente disponível: ${productsNotAvailable.map(
                  //     item => (
                  //       <p>Item: ${item.product} - Disponível: ${parseInt(item.available)}</p>
                  //     ),
                  //   )}. Vamos te redirecionar para o carrinho para que você possa excluí-lo:

                  //       `,
                  // );

                  setErrorAction([
                    () => {
                      window.location.reload();
                    },
                  ]);
                  break;

                case 'far_from_restaurant':
                  history.push(`/app/farlocationerror`);
                  break;

                case 'no_geolocation_sent':
                  history.push(`/app/locationError`);
                  break;

                case 'wrong_product':
                  setErrorComponent(true);
                  setErrorMessage(
                    'Esse produto não existe neste restaurante, favor verificar no cardápio.',
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                case 'table_session_not_found':
                  localStorage.removeItem('@garcomdigital:sessionKey');
                  setSessionKey('');
                  tableLogin(lastOrder, command_table_number, closeCart);

                  break;

                case 'lounge_price_limit':
                  setErrorComponent(true);
                  setErrorMessage(err.response.data.message);
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  break;

                default:
                  setErrorComponent(true);
                  setErrorMessage(
                    `Ocorreu um erro ao efetuar o pedido. Favor tentar novamente, ou mostar o erro ao gerente. : ${err.message}`,
                  );
                  setErrorAction([
                    () => {
                      history.push(`/menu/${restaurantId}`);
                    },
                  ]);
                  closeCart();
                  break;
              }
            } else {
              await api.post('/public/client/log-error', {
                error: err.message,
              });
            }
          }
        }
      }
    },

    [
      orders,
      sessionKey,
      tableLogin,
      userLogin,
      history,
      setBasketId,
      setBillId,
      userLocation,
      restaurantLocationLimited,
      restaurantId,
      setSessionKey,
      logOut,
      userAddressId,
      userChange,
      userChosePaySms,
      userPaymentMethod,
      restaurantCouponCode,
      setRestaurantCouponCode,
      setPixCodePayment,
      userDeliveryType,
      setZoopPixCodePayment,
      isScheduling,
      scheduledTime,
    ],
  );

  const closeOrder = useCallback(() => {
    localStorage.removeItem('@garcomdigital:order');
    localStorage.removeItem('@garcomdigital:cart');
    localStorage.removeItem('@garcomdigital:basketId');

    setOrders([]);
  }, []);

  const value = useMemo(
    () => ({
      addToOrder,
      orders,
      closeOrder,
      setErrorComponent,
      setOrdersTotalValue,
      ordersTotalValue,
    }),
    [
      addToOrder,
      orders,
      closeOrder,
      setErrorComponent,
      setOrdersTotalValue,
      ordersTotalValue,
    ],
  );

  return loadingPix ? (
    <LoadingPage
      duration={3}
      text={phraseToPix ? 'Gerando código PIX' : 'Fazendo o pedido ...'}
    />
  ) : (
    <OrderContext.Provider value={value}>
      {children}
      <OrderError
        error={errorComponent}
        errorMessage={errorMessage}
        errorAction={errorAction}
      />
      <Modal
        isOpen={isModalErrorOpen}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>{modalErrorPhrase}</p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton onClick={toggleModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isProductErrorModalOpened}
        onRequestClose={toggleModalProductError}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>
            Olá, não poderemos concluir o seu pedido, pois o item a seguir não
            tem a quantidade suficiente disponível:
          </p>
          {productsNotAvailable &&
            productsNotAvailable.map(item => (
              <p>
                Item: {item.product} - Disponível: {parseInt(item.available)}
              </p>
            ))}
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton
            onClick={() => {
              errorAction[0]();
              toggleModalProductError();
            }}
          >
            Ok, entendi
          </ConfirmButton>
        </ModalFooter>
      </Modal>
    </OrderContext.Provider>
  );
};

function useOrder() {
  const context = useContext(OrderContext);

  if (!context) {
    console.log('useOrder must be within a provider');
  }

  return context;
}

export { OrderProvider, useOrder };
