import styled, { css } from 'styled-components';

export const Container = styled.nav`
  height: 105px;
  width: 100%;
  max-width: 410px;
  padding: 10px;
  padding-left: 14px;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index:  ${props => (props?.isVisible ? "1" : "0")};
  background-color: #fff;
  margin-top: 0px;
  opacity: ${props => (props?.isVisible ? "1" : "0")};
  display: ${props => (props?.isVisible ? "inline-flex" : "none")};
  transition: opacity 0.2s ease-in-out;
  .scroll-container {
    display: flex;
    align-content: center;
    padding-bottom: 15px;
  }
`;
