import {
  addDays,
  addMinutes,
  endOfDay,
  format,
  startOfDay,
  subMinutes,
} from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  ConfirmButton,
  Container,
  HourCheckbox,
  Subtitle,
  Title,
  HoursAvailable,
  ConfirmButtonArea,
} from './styles';
import { MdCheck } from 'react-icons/md';
import { AnimatePresence } from 'framer-motion';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { useAuth } from '../../../context/auth';
import { Spinner } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';

import getDay from 'date-fns/getDay';
import { ptBR } from 'date-fns/locale';
registerLocale('pt', ptBR);
export const SchedulerPage = () => {
  const history = useHistory();
  const { setScheduledTime, restaurantId } = useAuth();

  const [days, setDays] = useState([]);
  const [hours, setHours] = useState([]);

  const [hourSelected, setHourSelected] = useState(null);
  const [restaurantSettings, setRestaurantSettings] = useState(null);

  const [loadingTimes, setLoadingTimes] = useState(false);
  const [maxDate, setMaxDate] = useState(null);
  const [activeDays, setRestaurantActiveDays] = useState([]);

  const isWeekday = date => {
    const day = getDay(date);

    const ref_date = new Date(date).getTime();

    if (ref_date < startOfDay(new Date())) {
      return false;
    }

    if (maxDate) {
      if (ref_date > maxDate.getTime()) {
        return false;
      }
    }
    return activeDays.includes(day);
  };

  const now = new Date();
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const getRestaurantConfigs = useCallback(async () => {
    try {
      const response = await api.get(
        `/client/order-scheduling/${restaurantId}`,
      );

      setRestaurantSettings(response.data);

      if (searchParams.get('type') === 'delivery') {
        const daysFilter = [...response.data.active_days]
          .map((item, index) => {
            return {
              day: index,
              active: item === 't' ? true : false,
            };
          })
          .filter(item => item.active)
          .map(item => item.day);

        setRestaurantActiveDays(daysFilter);

        const max_time_in_advance = response.data.max_time_in_advance;

        if (max_time_in_advance) {
          const start = startOfDay(new Date());
          const end = endOfDay(addMinutes(start, max_time_in_advance));

          setMaxDate(end);
        } else {
          setMaxDate(null);
        }
      } else {
        const daysFilter = [...response.data.withdrawal_active_days]
          .map((item, index) => {
            return {
              day: index,
              active: item === 't' ? true : false,
            };
          })
          .filter(item => item.active)
          .map(item => item.day);

        setRestaurantActiveDays(daysFilter);

        const max_time_in_advance =
          response.data.withdrawal_max_time_in_advance;

        if (max_time_in_advance) {
          const start = startOfDay(new Date());
          const end = endOfDay(addMinutes(start, max_time_in_advance));

          setMaxDate(end);
        } else {
          setMaxDate(null);
        }
      }
    } catch (err) {
      console.log('getRestaurantConfigs error: ', err);
    }
  }, []);

  const confirmSchedule = () => {
    console.log('hourSelected: ', hourSelected);
    if (!startDate || !hourSelected) {
      toast.error('Selecione um dia e horário para prosseguir');
      return;
    }

    setScheduledTime(new Date(hourSelected));
    history.push('/app/payments-delivery');
  };

  const fillDaysArray = () => {
    for (let i = 0; i < 7; i++) {
      setDays(state => [
        ...state,
        {
          date: addDays(now, i),
          weekDay: addDays(now, i).getDay(),
        },
      ]);
    }
  };

  const getTimes = async day => {
    setLoadingTimes(true);
    try {
      const response = await api.get(
        '/client/order-scheduling/available-times',
        {
          params: {
            restaurant_id: restaurantId,
            day,
            with_withdrawal: searchParams.get('type') === 'withdrawal',
          },
        },
      );

      setHours(response.data.times);
    } catch (err) {
      console.log('getTimes error: '.err);
    }
    setLoadingTimes(false);
  };

  const getFirstLetter = word => {
    return word.substring(0, 1).toUpperCase();
  };

  const handleDateUpdate = date => {
    setStartDate(date);
  };

  useEffect(() => {
    console.log('Rendered');
    fillDaysArray();
    getRestaurantConfigs();
  }, []);

  useEffect(() => {
    const day = startDate.getDay();
    if (activeDays.includes(day)) {
      getTimes(startDate.toISOString());
    }
  }, [startDate, activeDays]);

  return (
    <Container>
      <header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FiArrowLeft
          onClick={() => {
            history.push('/app/delivery-type');
          }}
          color="rgba(251, 74, 32, 1)"
          size={20}
          style={{ marginTop: '3px' }}
        />
        <Title>Escolha uma opção</Title>
      </header>

      <ReactDatePicker
        dateFormat="dd MMMM yyyy"
        formatWeekDay={day => getFirstLetter(day)}
        locale="pt"
        selected={startDate}
        onSelect={e => handleDateUpdate(e)}
        onChange={e => handleDateUpdate(e)}
        customInput={<span></span>}
        open={true}
        filterDate={isWeekday}
      />

      <div
        style={{
          padding: '10px 0 20px 0',
          borderBottom: '1px solid #CDCDCD',
          borderTop: '1px solid #CDCDCD',
          margin: '350px 0px 20px 0',
        }}
      >
        <Subtitle style={{ marginBottom: '10px' }}>Hora</Subtitle>

        <HoursAvailable>
          <AnimatePresence>
            {loadingTimes ? (
              <Spinner style={{ margin: '15px auto', display: 'block' }}>
                <span className="visually-hidden">Carregando...</span>
              </Spinner>
            ) : (
              hours?.map((h, i) => {
                const hour = new Date(h);
                const isDisabled =
                  subMinutes(hour, restaurantSettings.time_in_advance) < now;

                return (
                  <HourCheckbox
                    initial={{ x: -200 }}
                    animate={{ x: 0 }}
                    exit={{ x: -200 }}
                    transition={{
                      type: 'spring',
                      stiffness: 50,
                      mass: 1,
                    }}
                    key={i}
                    onClick={() => {
                      if (!isDisabled) {
                        setHourSelected(h);
                      }
                    }}
                    selected={h === hourSelected}
                    disabled={isDisabled}
                  >
                    <div>
                      {!isDisabled && <MdCheck size={15} color="#ffffff" />}
                    </div>
                    <p style={{ color: isDisabled && '#979797' }}>
                      {format(hour, "HH'h'mm")}
                    </p>
                  </HourCheckbox>
                );
              })
            )}
          </AnimatePresence>
        </HoursAvailable>
      </div>

      <ConfirmButton onClick={() => confirmSchedule()}>Confirmar</ConfirmButton>
    </Container>
  );
};
