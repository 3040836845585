import React from 'react';
import { ReviewItemContainer } from './styles';
import ReactStars from 'react-rating-stars-component';
import { FaRegStar, FaStar } from 'react-icons/fa';

export const ReviewItem = ({ option, value, setValue }) => {
  return (
    <ReviewItemContainer>
      <span>{option.text}</span>
      <ReactStars
        classNames={'stars'}
        count={5}
        value={value[option.type]}
        onChange={e =>
          setValue(state => {
            return { ...state, [option.type]: e };
          })
        }
        size={25}
        activeColor="#FFA814"
        color="#FFA814"
        emptyIcon={<FaRegStar />}
        filledIcon={<FaStar />}
      />
    </ReviewItemContainer>
  );
};
