import React from 'react';
import { CloseModal, ModalContainer, ModalContent } from './styles';
import { Button } from 'ui-kit-takeat';
import waiterCashback from '../../assets/images/waiterCashback.svg';
import { IoIosClose } from 'react-icons/io';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ModalCTACashback = ({ isOpen, setIsOpen }) => {
  const history = useHistory();

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalContainer
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          isOpen={isOpen}
          onClick={() => setIsOpen(false)}
        >
          <ModalContent onClick={e => e.stopPropagation()}>
            <CloseModal onClick={() => setIsOpen(false)}>
              <IoIosClose color="white" />
            </CloseModal>
            <img src={waiterCashback} />
            <h1>Quer economizar na sua próxima compra?</h1>
            <p>
              Cadastre-se agora para começar a receber cashback! Clique aqui 👇
            </p>
            <Button
              title="ECONOMIZAR AGORA"
              buttonColor="#FFB32F"
              containerStyles={{ width: '100%', borderRadius: 10 }}
              textStyle={{ fontWeight: 700 }}
              onClick={() => {
                setIsOpen(false);
                history.push('/club/register');
              }}
            />
          </ModalContent>
        </ModalContainer>
      )}
    </AnimatePresence>
  );
};
