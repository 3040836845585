import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const OrderValue = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  p {
    font-weight: bold;
    color: #333;
  }
`;

export const ButtonLink = styled.button`
  border: none;
  text-decoration: underline;
  color: ${props => (props.group ? '#c8131b' : '#0CCAB4')};
  background-color: transparent;
  font-weight: bold;
  margin-left: 0px;
  margin-bottom: 10px;
`;

export const OrderTime = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const Order = styled.div`
  width: 100%;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: #333;
  border-radius: 7px;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  border: 1px solid ${props => (props.isWithdrawal ? '#F7D9A6' : '#bff2ec')};

  & + div {
    margin-top: 16px;
  }
`;
export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 18px;
`;
export const OrderBody = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const OrderFooter = styled(Link)`
  text-decoration: none;
  margin-top: 16px;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #33333310;

  p {
    font-size: 14px;
    color: #333;
  }
`;

export const OrderStatus = styled.div`
  margin-top: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  p {
    color: green;
  }

  .progress-bar-animated {
    animation-direction: reverse;
  }
`;

export const OrderType = styled.div`
  background-color: ${props => (props.isWithdrawal ? '#F7D9A6' : '#bff2ec')};
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 20px;
  text-align: center;
`;
