import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
  top: 50%;
  transform: translateY(-50%) !important;

  .modal-content {
    padding: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  img {
    width: 125px;
  }

  button {
    border-radius: 10px;
    width: 100%;

    span {
      font-weight: 700;
    }
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  p {
    margin: 0;
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }

  b {
    color: #2ec9b7;
  }
`;
