/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { Container, SkeletonContainer } from './styles';
import MenuScrollItem from './MenuScrollItem';
import { useAuth } from '../../context/auth';
import { Skeleton } from '../Skeleton';

function MenuScrollNav({
  menu,
  handleExpandCategory,
  tableType,
  menuTranslationsEnabled,
  menuLanguage,
  categoryShowing,
}) {
  const scrollingMenuRef = useRef(null);
  const { setIsMenuVisible } = useAuth();
  useEffect(() => {
    const handleScroll = () => {
      if (scrollingMenuRef.current) {
        const scrollingMenuRect = scrollingMenuRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (scrollingMenuRect.top < 0) {
          setIsMenuVisible(true);
        } else {
          setIsMenuVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return tableType === 'delivery' ? (
    <Container ref={scrollingMenuRef}>
      <ScrollContainer
        className="scroll-container"
        vertical={false}
        style={{ height: 'fit-content' }}
      >
        {menu.length === 0 && (
          <div style={{ display: 'flex', gap: 10, padding: '5px 0 0 5px' }}>
            <SkeletonContainer>
              <Skeleton width="65px" height="65px" radius="65px" />
              <Skeleton height="12px" radius="4px" />
            </SkeletonContainer>
            <SkeletonContainer>
              <Skeleton width="65px" height="65px" radius="65px" />
              <Skeleton height="12px" radius="4px" />
            </SkeletonContainer>
            <SkeletonContainer>
              <Skeleton width="65px" height="65px" radius="65px" />
              <Skeleton height="12px" radius="4px" />
            </SkeletonContainer>
            <SkeletonContainer>
              <Skeleton width="65px" height="65px" radius="65px" />
              <Skeleton height="12px" radius="4px" />
            </SkeletonContainer>
            <SkeletonContainer>
              <Skeleton width="65px" height="65px" radius="65px" />
              <Skeleton height="12px" radius="4px" />
            </SkeletonContainer>
            <SkeletonContainer>
              <Skeleton width="65px" height="65px" radius="65px" />
              <Skeleton height="12px" radius="4px" />
            </SkeletonContainer>
          </div>
        )}
        {menu
          .filter(cat => cat.available_in_delivery && !cat.is_exclusive)
          .map((category, idx) => {
            return (
              <React.Fragment key={category.id}>
                {category.products.length > 0 && (
                  <MenuScrollItem
                    category={category}
                    handleExpandCategory={handleExpandCategory}
                    menuTranslationsEnabled={menuTranslationsEnabled}
                    menuLanguage={menuLanguage}
                    isActive={categoryShowing === idx}
                  />
                )}
              </React.Fragment>
            );
          })}
      </ScrollContainer>
    </Container>
  ) : tableType === 'menu' ? (
    <Container>
      <ScrollContainer className="scroll-container">
        {menu
          .filter(cat => !cat.is_exclusive)
          .map((category, idx) => (
            <React.Fragment key={category.id}>
              {category.products.length > 0 && (
                <MenuScrollItem
                  category={category}
                  handleExpandCategory={handleExpandCategory}
                  menuTranslationsEnabled={menuTranslationsEnabled}
                  menuLanguage={menuLanguage}
                  isActive={categoryShowing === idx}
                />
              )}
            </React.Fragment>
          ))}
      </ScrollContainer>
    </Container>
  ) : (
    <Container>
      <ScrollContainer
        className="scroll-container"
        vertical={false}
        style={{ height: 'fit-content' }}
      >
        {menu
          .filter(cat => cat.available && !cat.is_exclusive)
          .map((category, idx) => (
            <React.Fragment key={category.id}>
              {category.products.length > 0 && (
                <MenuScrollItem
                  category={category}
                  handleExpandCategory={handleExpandCategory}
                  menuTranslationsEnabled={menuTranslationsEnabled}
                  menuLanguage={menuLanguage}
                  isActive={categoryShowing === idx}
                />
              )}
            </React.Fragment>
          ))}
      </ScrollContainer>
    </Container>
  );
}

export default MenuScrollNav;
