import React, { useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import InputMask from 'react-input-mask';
import Modal from 'react-modal';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Cards from 'react-credit-cards';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';

import LoadingPayment from '../Delivery/LoadingPayment';

import {
  Container,
  Content,
  Title,
  Card,
  InputGroup,
  SubmitButton,
  ChangeMethodButton,
  ButtonGroup,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
} from './styles';
import api from '../../services/api';
import { useOrder } from '../../context/order';
import { useAuth } from '../../context/auth';

const InputCardCloseTable = () => {
  const { modalErrorPhrase } = useOrder();
  const history = useHistory();
  const location = useLocation();

  const formRef = useRef(null);
  const { tableType, sessionKey } = useAuth();
  const [name, setName] = useState('Nome no cartão');
  const [number, setNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  // const [cpf, setCpf] = useState('');
  const [focused, setFocused] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [modalPaymentErrorIsOpened, setIsModalPaymentErrorOpened] = useState(
    false,
  );

  function toggleModalPaymentError() {
    setIsModalPaymentErrorOpened(!modalPaymentErrorIsOpened);
  }

  function handleGoPayment() {
    // alert(`${message}, tente novamente ...`);
    tableType.toString() === 'prepaid'
      ? history.push('/app/prepaid/payment-methods')
      : history.push('/app/payments-delivery');
  }

  function changeFocus(e) {
    setFocused(e.target.id);
  }

  async function handleMakeToken(cardName, cardExpiry, cardNumber, cardCvc) {
    try {
      setIsLoading(true);
      const response = await api.post(`/client/token-card`, {
        holder_name: cardName,
        expiration_month: cardExpiry.slice(0, 2),
        expiration_year: cardExpiry.slice(3, 5),
        card_number: cardNumber,
        security_code: cardCvc,
      });

      const { id } = response.data;

      if (location.account === 'individual') {
        await api.post('client/card/pay-bill', {
          session_key: sessionKey,
          payment_token: id,
        });
      }

      if (location.account === 'table') {
        await api.post('client/card/pay-session', {
          session_key: sessionKey,
          payment_token: id,
        });
      }
      setIsLoading(false);

      history.push('/app/review');
    } catch (err) {
      toggleModalPaymentError();
      const { error } = err.response.data;
      console.log(error);
    }
  }

  async function handleSubmit(data) {
    try {
      if (formRef.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        name: Yup.string()
          .min(2, 'Nome obrigatório')
          .matches(/^[A-Za-z ]+$/, 'Somente letras')
          .required('Nome obrigatório'),
        number: Yup.string()
          .min(16, 'Favor preencher todos os números')
          .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
          .required('Número obrigatório'),
        expiry: Yup.string()
          .required('Vencimento obrigatório')
          .matches(/^\d{2}\/\d{2}$/, 'Formato da data inválido (MM/AA)'),
        cvc: Yup.string()
          .matches(/^[0-9]+$/, 'Somente números, sem espaços em branco')
          .required('Código obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      handleMakeToken(data.name, data.expiry, data.number, data.cvc);
    } catch (err) {
      const errors = getValidationErrors(err);
      if (formRef.current) {
        formRef.current.setErrors(errors);
      }
    }
  }

  return isLoading ? (
    <>
      <LoadingPayment title="Processando o pagamento ..." />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>
            {modalErrorPhrase ||
              'Erro ao efetuar o pagamento, tente novamente ...'}
          </p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton onClick={handleGoPayment}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalPaymentErrorIsOpened}
        onRequestClose={toggleModalPaymentError}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Erro ao efetuar o pagamento, tente novamente ...</p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton
            onClick={() => {
              toggleModalPaymentError();
              setIsLoading(false);
            }}
          >
            Ok, entendi
          </ConfirmButton>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    <Container>
      <Content>
        <Title>
          <h1>Estamos quase lá!</h1>
          <p>
            Preencha com os dados do seu cartão para efetuarmos o fechamento da
            conta.
          </p>
        </Title>
        <Card>
          <Cards
            cvc={cvc}
            expiry={expiry}
            name={name}
            number={number}
            focused={focused}
          />
        </Card>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <Input
            name="number"
            onChange={e => setNumber(e.target.value)}
            placeholder="Número do cartão"
            maxLength="16"
            id="number"
            onClick={changeFocus}
            title="Somente Números"
          />

          <Input
            name="name"
            onChange={e => setName(e.target.value)}
            placeholder="Nome (O mesmo escrito no cartão)"
            id="name"
            onClick={changeFocus}
          />

          {/* <Input
            name="cpf"
            onChange={e => setCpf(e.target.value)}
            placeholder="CPF (Somente números)"
            onClick={changeFocus}
            id="cpf"
            maxLength="11"
          /> */}

          <InputGroup>
            <InputMask
              mask="99/99"
              maskChar=""
              onChange={e => setExpiry(e.target.value)}
            >
              <Input
                id="expiry"
                name="expiry"
                placeholder="Validade - xx/xx"
                onClick={changeFocus}
                type="text"
              />
            </InputMask>
            <Input
              name="cvc"
              onChange={e => setCvc(e.target.value)}
              placeholder="Cód. de segurança"
              onClick={changeFocus}
              id="cvc"
              maxLength="4"
            />
          </InputGroup>

          <ButtonGroup>
            <ChangeMethodButton to="/app/closeaccount">
              Voltar
            </ChangeMethodButton>
            <SubmitButton type="submit">Efetuar pagamento</SubmitButton>
          </ButtonGroup>
        </Form>
      </Content>
    </Container>
  );
};

export default InputCardCloseTable;
