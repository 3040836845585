import { Modal } from 'reactstrap';
import styled from 'styled-components';

export const StyledModalContainer = styled(Modal)`
  top: 50%;
  transform: translateY(-50%) !important;

  .modal-content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 16px;
  }

  .modal-header {
    padding: 0;
    justify-content: center;
    border-bottom: none;

    h2 {
      margin: 0;
      color: #333;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }

  .modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    border-top: none;

    button {
      width: 100%;
      border-radius: 10px;
      margin: 0;
      span {
        font-weight: 700;
      }
    }
  }
`;

export const PhoneDisplay = styled.span`
  border-bottom: 1px solid #cdcdcd;
  padding: 8px 58px;
  color: #6c6c6c;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
`;

export const CashbackDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  span {
    color: #2ec9b7;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
`;

export const BirthdayInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;

  span {
    color: #333;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid #c8131b;
  border-radius: 50%;

  svg {
    color: #c8131b;
    font-size: 14px;
  }
`;

export const ForgotPass = styled.span`
  color: #2ec9b7;
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  width: 100%;
  text-align: left;
`;
